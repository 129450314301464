import { useEffect, useState } from 'react'
import { Globe, List, Map, Zap } from 'react-feather'
import styled, { css } from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as Stats } from 'src/Images/stats.svg'

import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Grid,
  Link,
  MenuItem,
  Select,
} from '@material-ui/core'

import { ViewType } from 'src/store/explore/model'
import { ExploreTabs, Routes } from 'src/models/routes'
import { trackEvent } from 'src/utils'
import { setCollectionActive, updateViewType } from 'src/store/explore/actions'
import {
  activeAreaSelector,
  activeAreaTypeSelector,
  getCurrentViewType,
  insightsActiveSelector,
} from 'src/store/explore/selectors'
import { collectionSelectors } from 'src/store/collection/selectors'

const HeaderButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.15, 2)};

    &.active {
      color: #fff;
      border: 1px solid ${theme.palette.greys.black};
      background-color: ${theme.palette.greys.black};
    }

    ::last-child {
      margin-right: 0;
    }

    svg {
      width: 20px;
      margin-right: ${theme.spacing(1)}px;
    }
  `
)

const SepratedGrid = styled(Grid)(
  ({ theme }) => css`
    border-right: 1px solid ${theme.palette.greys.light};
  `
)

const NavGrid = styled(Grid)`
  height: 100%;
  padding-left: 3rem;
`

interface NavButtonProps {
  disabled?: boolean
}

const NavButton = styled(Link)<NavButtonProps>(
  ({ theme, disabled = false }) => css`
    position: relative;

    font-size: 1rem;
    text-decoration: none;
    color: ${theme.palette.greys.normal};
    cursor: pointer;
    font-weight: 600;
    padding-left: 1.8rem;

    :hover {
      text-decoration: none;
    }

    svg {
      position: absolute;
      height: 1.25rem;
      top: 50%;
      left: 0;
      margin-top: -0.625rem;
    }

    ${disabled
      ? `:hover { 
    color: ${theme.palette.greys.normal};
    }`
      : `
    &.active,
    :hover {
      color: ${theme.palette.greys.black};
      ::before {
        border-bottom: 4px solid #111;
        content: '';
        position: absolute;
        width: 100%;
        top: 2.5rem;
        left: 0;
      }
    }
    `}

    margin-right: 4rem;
  `
)

interface ViewTypeButtonProps extends ButtonProps {
  viewType: ViewType
}

const ViewTypeButton: React.FC<ViewTypeButtonProps> = props => {
  const dispatch = useDispatch()
  const currentViewType = useSelector(getCurrentViewType)

  const { children, viewType, ...other } = props

  return (
    <HeaderButton
      {...other}
      variant={currentViewType === viewType ? 'contained' : 'outlined'} // TODO fix the selction logic or something?
      color={currentViewType === viewType ? 'primary' : 'default'}
      onClick={() => {
        dispatch(updateViewType(viewType))
      }}
    >
      {children}
    </HeaderButton>
  )
}

const ExploreNavbar: React.FC = () => {
  const [activeTab, setActiveTab] = useState<ExploreTabs>(ExploreTabs.Map)

  const history = useHistory()
  const { pathname } = useLocation()

  const dispatch = useDispatch()

  const activeArea = useSelector(activeAreaSelector)
  const activeAreaType = useSelector(activeAreaTypeSelector)
  const collections = useSelector(collectionSelectors.selectAll)

  const insightsActive = useSelector(insightsActiveSelector)

  useEffect(() => {
    setActiveTab(
      Object.values(ExploreTabs).find(tab => pathname.endsWith(tab)) ??
        ExploreTabs.Map
    )
  }, [pathname])

  return (
    <div className="nav-bottom">
      <Grid container>
        <SepratedGrid item md={2}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Select
              displayEmpty
              variant="outlined"
              className="collection-selector"
              value={activeArea?.collectionId ?? ''}
              onChange={event => {
                if (activeArea?.collectionId !== event.target.value) {
                  dispatch(setCollectionActive(event.target.value as string))
                }
              }}
            >
              {collections.map(collection => (
                <MenuItem key={collection.id} value={collection.id}>
                  {collection.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </SepratedGrid>
        <Grid item md={8}>
          <NavGrid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <NavButton
              role="link"
              className={activeTab === ExploreTabs.Map ? 'active' : ''}
              onClick={() => {
                trackEvent('mhs_web_heatmap', {
                  level: activeAreaType,
                  reference: 'tab',
                })
                history.push(Routes.Explore + ExploreTabs.Map)
              }}
            >
              <Map />
              Map view
            </NavButton>
            <NavButton
              role="link"
              className={activeTab === ExploreTabs.Data ? 'active' : ''}
              onClick={() => {
                trackEvent('mhs_web_list', {
                  level: activeAreaType,
                  reference: 'tab',
                })
                history.push(Routes.Explore + ExploreTabs.Data)
              }}
            >
              <List />
              List view
            </NavButton>
            <NavButton
              role="link"
              disabled={!insightsActive}
              className={activeTab === ExploreTabs.Insights ? 'active' : ''}
              onClick={() => {
                if (!insightsActive) return
                trackEvent('mhs_web_insight', {
                  level: activeAreaType,
                  reference: 'tab',
                })
                history.push(Routes.Explore + ExploreTabs.Insights)
              }}
            >
              <Zap />
              Insights
            </NavButton>
          </NavGrid>
        </Grid>
        <Grid item md={2}>
          <Box display="flex" justifyContent="flex-end" marginRight="24px">
            <ButtonGroup disableElevation>
              <ViewTypeButton viewType={ViewType.Statistical}>
                <Stats />
                Statistical
              </ViewTypeButton>
              <ViewTypeButton viewType={ViewType.Geographical}>
                <Globe />
                Geographical
              </ViewTypeButton>
            </ButtonGroup>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default ExploreNavbar
