import React from 'react'
import styled, { css } from 'styled-components'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import {
  FormLabel,
  FormControl,
  TooltipProps,
  FormHelperText,
  TextFieldProps,
  Tooltip as MuiTooltip,
  TextField as MuiTextField,
} from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

interface RequiredProps {
  id: string
  name: string
  control: Control
  errors?: FieldErrors
  defaultValue?: string
  // If tooltip props are provided then we display the tooltip
  tooltipProps?: Omit<TooltipProps, 'children'>
}

const LabelContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
      fill: ${theme.palette.greys.light};
      font-size: ${theme.typography.pxToRem(16)};
    }
  `
)

const Tooltip = styled(props => (
  <MuiTooltip
    classes={{ popper: props.className, tooltip: 'tooltip', arrow: 'arrow' }}
    {...props}
  />
))`
  & .tooltip {
    margin-left: -10px;
  }

  & .arrow {
    left: 12px !important;
  }
`

const TextField = styled(MuiTextField)`
  margin: ${({ theme, error }) => (error ? 0 : theme.spacing(0, 0, 1))};
`

type Props = TextFieldProps & RequiredProps

const FormTextField: React.FC<Props> = ({
  name,
  label,
  errors,
  control,
  className,
  tooltipProps,
  defaultValue = '',
  variant = 'outlined',
  onChange: customOnChange,
  ...props
}) => {
  const fieldError = errors && errors[name]
  const hasError = !!fieldError

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, ...renderProps }) => (
        <FormControl className={className}>
          <LabelContainer>
            {label && (
              <>
                <FormLabel htmlFor={props.id}>{label}</FormLabel>{' '}
                {!!tooltipProps && (
                  <Tooltip {...tooltipProps}>
                    <HelpOutlineIcon />
                  </Tooltip>
                )}
              </>
            )}
          </LabelContainer>
          <TextField
            error={hasError}
            variant={variant}
            InputLabelProps={{ shrink: true }}
            onChange={customOnChange || onChange}
            {...renderProps}
            {...props}
          />
          {hasError && (
            <FormHelperText error={hasError}>
              {fieldError?.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}

export default React.memo(FormTextField)
