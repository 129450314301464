import { Mortgagee } from 'src/store/mortgagees/model'

const customerColor = '#F1B345'

const oranges = ['#463519', '#73572B', '#B88B45']

const blues = [
  '#296686',
  '#3A7FA0',
  '#5799BB',
  '#73B4D7',
  '#8DCDE2',
  '#CEF3FF',
  '#2BC2C2',
]

export interface ColorInferenceData {
  mortgagee: Mortgagee
  value: number
}

export const mapColors = (mortgageeData: ColorInferenceData[]) => {
  const customer = mortgageeData.find(
    mortgageeData => mortgageeData.mortgagee.isCustomer
  )

  const colored = mortgageeData
    .filter(mortgageeData => !mortgageeData.mortgagee.isCustomer)
    .sort((ma, mb) => (ma.value > mb.value ? -1 : 1))
    .reduce(
      (out, mortgagee, index) => ({
        ...out,
        [mortgagee.mortgagee.id]:
          index + 1 > oranges.length
            ? blues[(index - oranges.length) % blues.length]
            : oranges[index],
      }),
      <any>{}
    )

  if (customer) {
    colored[customer.mortgagee.id] = customerColor
  }
  return colored
}
