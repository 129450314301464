import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllCollections, getCollection } from 'src/store/collection/actions'
import { RootState } from 'src/store/rootReducer'
import { ActiveArea, ViewType } from 'src/store/explore/model'
import { getAllMortgagees } from 'src/store/mortgagees/actions'
import {
  getAllMeshblocks,
  getAllSA1s,
  getAllSA2s,
  getMeshblock,
  getSA1,
  getSA2,
} from 'src/store/statisticalAreas/actions'
import {
  getAllPostcodes,
  getAllSuburbs,
  getPostcode,
  getSuburb,
} from 'src/store/geographicalAreas/actions'
import { statisticalAreasSelectors } from 'src/store/statisticalAreas/selectors'
import { geographicalAreaSelectors } from 'src/store/geographicalAreas/selectors'
import {
  fromStatsGeoParents,
  getStatisticalAreaType,
  getStatisticalAreaTypeFromGeo,
} from 'src/store/statisticalAreas/utils'
import {
  StatisticalAreaType,
  StatisticalGeoParents,
  StatisticalParents,
} from 'src/store/statisticalAreas/model'
import {
  fromGeoGeoParents,
  getGeoAreaTypeFromGeo,
  getGeographicalAreaType,
} from 'src/store/geographicalAreas/util'
import {
  GeographicalAreaType,
  GeographicalGeoParents,
  GeographicalParents,
} from 'src/store/geographicalAreas/model'
import { GeoArea } from 'src/store/map/model'

export const init = createAsyncThunk<void, void, { state: RootState }>(
  'explore/init',
  async (_active: void, thunkAPI) => {
    await thunkAPI.dispatch(getAllMortgagees())

    const { payload } = await thunkAPI.dispatch(getAllCollections())
    await thunkAPI.dispatch(setCollectionActive(payload.data.collections[0].id)) // TODO retain last selected collection?
  }
)

export const setCollectionActive = createAsyncThunk<
  ActiveArea,
  string,
  {
    state: RootState
  }
>('explore/setCollectionActive', async (collectionId: string, thunkAPI) => {
  let topLevel

  switch (thunkAPI.getState().explore.currentViewType) {
    case ViewType.Statistical:
      topLevel = getAllSA2s({ collectionId })
      break
    case ViewType.Geographical:
      topLevel = getAllPostcodes({ collectionId })
      break
  }

  await Promise.all([
    thunkAPI.dispatch(getCollection({ collectionId })),
    thunkAPI.dispatch(topLevel),
  ])

  return <ActiveArea>{
    collectionId: collectionId,
    activeChildId: undefined,
  }
})

export const setActiveChildFromGeo = createAsyncThunk<
  ActiveArea,
  GeoArea,
  {
    state: RootState
  }
>('explore/setActiveChildFromGeo', async (geoArea, { dispatch, getState }) => {
  switch (getState().explore.currentViewType) {
    case ViewType.Statistical: {
      const parents = fromStatsGeoParents(geoArea.parents)
      await setStatAreaActive(
        getStatisticalAreaTypeFromGeo(geoArea.parents),
        geoArea.id,
        parents,
        dispatch
      )
      break
    }
    case ViewType.Geographical: {
      const parents = fromGeoGeoParents(geoArea.parents)
      await setGeographicalAreaActive(
        getGeoAreaTypeFromGeo(geoArea.parents),
        geoArea.id,
        parents,
        dispatch
      )
      break
    }
  }

  return <ActiveArea>{
    collectionId: getState().explore.activeArea?.collectionId,
    activeChildId: geoArea.id,
  }
})

export const manuallySetActiveWithGeo = createAsyncThunk<
  ActiveArea,
  { id: string; parents: StatisticalGeoParents | GeographicalGeoParents },
  {
    state: RootState
  }
>(
  'explore/manuallySetActive',
  async ({ id, parents }, { dispatch, getState }) => {
    switch (getState().explore.currentViewType) {
      case ViewType.Statistical:
        await setStatAreaActive(
          getStatisticalAreaTypeFromGeo(parents),
          id,
          fromStatsGeoParents(parents),
          dispatch
        )
        break
      case ViewType.Geographical:
        await setGeographicalAreaActive(
          getGeoAreaTypeFromGeo(parents),
          id,
          fromGeoGeoParents(parents),
          dispatch
        )
        break
    }

    return <ActiveArea>{
      collectionId: getState().explore.activeArea?.collectionId,
      activeChildId: id,
    }
  }
)

export const setActiveChild = createAsyncThunk<
  ActiveArea,
  string,
  {
    state: RootState
  }
>(
  'explore/setActiveChild',
  async (activeChildId: string, { dispatch, getState }) => {
    switch (getState().explore.currentViewType) {
      case ViewType.Statistical: {
        const child = statisticalAreasSelectors.selectById(
          getState(),
          activeChildId
        )

        if (child == null) throw Error('Unable to find new active area')

        await setStatAreaActive(
          getStatisticalAreaType(child),
          child.id,
          child.parents,
          dispatch
        )

        break
      }
      case ViewType.Geographical: {
        const child = geographicalAreaSelectors.selectById(
          getState(),
          activeChildId
        )

        if (child == null) throw Error('Unable to find new active area')

        await setGeographicalAreaActive(
          getGeographicalAreaType(child),
          child.id,
          child.parents,
          dispatch
        )

        break
      }
    }

    return <ActiveArea>{
      collectionId: getState().explore.activeArea?.collectionId,
      activeChildId,
    }
  }
)

const setGeographicalAreaActive = async (
  areaType: GeographicalAreaType,
  id: string,
  parents: GeographicalParents,
  dispatch: any
) => {
  const { collectionId, postcodeId } = parents
  switch (areaType) {
    case GeographicalAreaType.Collection:
      await dispatch(getAllPostcodes({ collectionId: id }))
      break
    case GeographicalAreaType.Postcode:
      await dispatch(
        getPostcode({ collectionId: collectionId!, postcodeId: id })
      )
      await dispatch(
        getAllSuburbs({ collectionId: collectionId!, postcodeId: id })
      )
      break
    case GeographicalAreaType.Suburb:
      await dispatch(
        getSuburb({
          collectionId: collectionId!,
          postcodeId: postcodeId!,
          suburbId: id,
        })
      )
      break
  }
}

const setStatAreaActive = async (
  areaType: StatisticalAreaType,
  id: string,
  parents: StatisticalParents,
  dispatch: any
) => {
  const { collectionId, sa2Id, sa1Id } = parents
  switch (areaType) {
    case StatisticalAreaType.Collection:
      await dispatch(getAllSA2s({ collectionId: id }))
      break
    case StatisticalAreaType.SA1:
      await dispatch(
        getSA1({ collectionId: collectionId!, sa2Id: sa2Id!, sa1Id: id })
      )
      await dispatch(
        getAllMeshblocks({
          collectionId: collectionId!,
          sa2Id: sa2Id!,
          sa1Id: id,
        })
      )
      break
    case StatisticalAreaType.SA2:
      await dispatch(getSA2({ collectionId: collectionId!, sa2Id: id }))
      await dispatch(getAllSA1s({ collectionId: collectionId!, sa2Id: id }))
      break
    case StatisticalAreaType.MeshBlock:
      await dispatch(
        getMeshblock({
          collectionId: collectionId!,
          sa2Id: sa2Id!,
          sa1Id: sa1Id!,
          meshblockId: id,
        })
      )
      break
  }
}

export const setCurrentViewType = createAction<ViewType>(
  'explore/SetCurrentViewType'
)

export const updateViewType = createAsyncThunk<
  void,
  ViewType,
  {
    state: RootState
  }
>('explore/updateViewType', async (active: ViewType, thunkAPI) => {
  await thunkAPI.dispatch(setCurrentViewType(active))
  await thunkAPI.dispatch(
    setCollectionActive(
      thunkAPI.getState().explore.activeArea?.collectionId ?? ''
    )
  )
})
