import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { PlayCircle, XCircle } from 'react-feather'
import { yupResolver } from '@hookform/resolvers/yup'

import { Box, TooltipProps, Typography } from '@material-ui/core'

import {
  ChangePasswordState,
  Container,
  ErrorContainer,
  Form,
  IllustrationContainer,
  IllustrationCornerContainer,
  PasswordRequirements,
  SubmitButton,
} from 'src/modules/auth/common'
import FormTextField from 'src/components/inputs/FormTextField'

import plantsLeft from 'src/Images/plants-bottom-left.svg'
import plantsRight from 'src/Images/plants-bottom-right.svg'
import { ReactComponent as InsightfulLogo } from 'src/Images/insightful-logo.svg'
import useAuth from 'src/hooks/useAuth'
import { object, ref, string } from 'yup'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Routes } from 'src/models/routes'
import { SessionStatus } from 'src/models/auth'
import { useHistory } from 'react-router'

const List = styled.ul(
  ({ theme }) => css`
    padding-left: ${theme.spacing(2)}px;
  `
)

const tooltipProps: Omit<TooltipProps, 'children'> = {
  arrow: true,
  placement: 'top-start',
  'aria-label': 'Password requirements',
  title: (
    <List>
      <li>Must be at least 10 characters long</li>
      <li>Must contain a lowercase character</li>
      <li>Must contain an uppercase character</li>
      <li>Must contain a number</li>
      <li>Must contain a symbol</li>
    </List>
  ),
}

const setPasswordSchema = object().shape({
  firstName: string().required('You must provide your first name'),
  lastName: string().required('You must provide your last name'),
  newPassword: PasswordRequirements.required('Please enter a new password'),
  confirmNewPassword: string()
    .required('Please re-enter your new password')
    .oneOf([ref('newPassword')], 'Passwords must match'),
})

// Okay you need to make this call the new fuction n the auth hook
// then update the user with the new tokens/profile as like the unblocked flow of the login method
// We should also restrict this route if our state isnt the right one
const ChangePassword: React.FC = () => {
  const [submitError, setSubmitError] = useState('')
  const { confirmPassword, status, isLoggedIn } = useAuth()
  const { state } = useLocation<ChangePasswordState>()
  const history = useHistory()

  const { control, errors, formState, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(setPasswordSchema),
    defaultValues: {
      firstName: state?.firstName,
      lastName: state?.lastName,
      newPassword: '',
      confirmNewPassword: '',
    },
  })
  const { isSubmitting } = formState

  const onSubmit = handleSubmit(
    async ({ firstName, lastName, newPassword }) => {
      try {
        await confirmPassword(newPassword, firstName, lastName)
      } catch (error) {
        if (error.code === 'NotAuthorizedException') {
          history.push(Routes.Login)
        }
        setSubmitError(error.message)
      }
    }
  )

  useEffect(() => {
    if (status != SessionStatus.PendingPassword) {
      history.push(isLoggedIn ? Routes.Explore : Routes.Login)
    }
  }, [status, history, isLoggedIn])

  return (
    <Container>
      <InsightfulLogo />
      {!!submitError && (
        <ErrorContainer>
          <XCircle />
          <Typography variant="subtitle1">{submitError}</Typography>
        </ErrorContainer>
      )}
      <Form onSubmit={onSubmit}>
        <Box mb={2}>
          <Typography variant="h4" align="center">
            Welcome
          </Typography>
          <Typography variant="subtitle1" align="center">
            We require a few details about yourself and a new password to
            replace the temporary password you were given in the email
            invitation.
          </Typography>
        </Box>
        <FormTextField
          fullWidth
          errors={errors}
          type="text"
          control={control}
          id="first-name"
          name="firstName"
          label="First name"
          placeholder="Enter your first name"
        />
        <FormTextField
          fullWidth
          errors={errors}
          type="text"
          control={control}
          id="last-name"
          name="lastName"
          label="Last name"
          placeholder="Enter your last name"
        />
        <FormTextField
          fullWidth
          errors={errors}
          type="password"
          control={control}
          id="new-password"
          name="newPassword"
          label="New password"
          tooltipProps={tooltipProps}
          placeholder="Enter password"
        />
        <FormTextField
          fullWidth
          type="password"
          errors={errors}
          control={control}
          label="Confirm Password"
          id="confirm-new-password"
          name="confirmNewPassword"
          tooltipProps={tooltipProps}
          placeholder="Re-enter password"
        />
        <SubmitButton
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          loading={isSubmitting}
        >
          <PlayCircle />
          Save
        </SubmitButton>
      </Form>
      <IllustrationContainer>
        <IllustrationCornerContainer>
          <img src={plantsLeft} alt="PlantsLeft" />
        </IllustrationCornerContainer>
        <div />
        <IllustrationCornerContainer>
          <img src={plantsRight} alt="PlantsRight" />
        </IllustrationCornerContainer>
      </IllustrationContainer>
    </Container>
  )
}

export default ChangePassword
