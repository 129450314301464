import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts'
import { Circle } from 'react-feather'
import { useState } from 'react'
import { LineData } from 'src/store/insights/model'
import ValueSelector from 'src/components/Views/Explore/Insight/ValueSelector'
import { useSelector } from 'react-redux'
import {
  allMortgageesSelector,
  youSelector,
} from 'src/store/mortgagees/selectors'
import { mapColors } from 'src/utils/mortgageeColor'
import { Paper } from '@material-ui/core'
import { getFormatter, ValueFormatter } from 'src/utils/valueFormatters'

export interface LineChartProps {
  title: string
  format: string
  lines: LineData[]
  labels: string[]
}

interface LineChartTooltip extends TooltipProps {
  valueFormatter: ValueFormatter
  colorMappings: any
  nameMappings: any
}

const LineChartTooltip: React.FC<LineChartTooltip> = ({
  active,
  payload,
  colorMappings,
  nameMappings,
  valueFormatter,
}) => {
  if (active && payload && payload.length) {
    return (
      <Paper elevation={2} style={{ padding: 15 }}>
        {payload.map(item => (
          <span key={item.name} className="color-bubble">
            <Circle
              style={{
                stroke: colorMappings[item.name],
                fill: colorMappings[item.name],
              }}
            />
            {nameMappings[item.name]} :{' '}
            {typeof item.value == 'number'
              ? valueFormatter(item.value)
              : item.value}
          </span>
        ))}
      </Paper>
    )
  }

  return null
}

const LSLineChart: React.FC<LineChartProps> = ({
  title,
  format,
  lines,
  labels,
}) => {
  const [activeIds, setActiveIds] = useState<string[]>(
    lines.map(line => line.uuid)
  ) // TODO init with the `you` value + one-two other and integrate with dropdown

  const mortgagees = useSelector(allMortgageesSelector)
  const you = useSelector(youSelector)

  const mortgageeLines = lines.flatMap(line => {
    const found = mortgagees.find(mortgagee => mortgagee.id === line.uuid)
    return found
      ? [{ ...found, name: found.isCustomer ? 'You' : found.name }]
      : []
  })

  const mappedColorData = mapColors(
    mortgageeLines.map(mortgagee => {
      const line = lines.find(line => mortgagee.id === line.uuid)
      return { mortgagee, value: line?.data[line.data.length - 1] ?? 0 }
    })
  )

  const nameMapping = mortgageeLines.reduce(
    (curr, line) => ({ ...curr, [line.id]: line.name }),
    {}
  )

  const activeLines = lines.filter(line => activeIds.includes(line.uuid))

  const lineData = labels.map((label, index) => ({
    label,
    ...activeLines.reduce(
      (out, line) => ({ ...out, [line.uuid]: line.data[index] }),
      {}
    ),
  }))

  const valueFormatter = getFormatter(format)

  return (
    <div className="volume-chart">
      <div className="heading">
        {title}
        <ValueSelector
          lines={lines}
          colorMap={mappedColorData}
          activeIds={activeIds}
          setActiveIds={setActiveIds}
        />
      </div>
      {you && (
        <div className="heading you-label">
          <div className="graph-legend">
            <span className="color-bubble">
              <Circle
                style={{
                  stroke: mappedColorData[you.id],
                  fill: mappedColorData[you.id],
                }}
              />
              You
            </span>
          </div>
        </div>
      )}
      <div className="chart-block">
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            data={lineData}
            margin={{
              top: 20,
              right: 20,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              vertical={false}
              stroke="#E5EBF0"
              strokeDasharray="1.5"
            />
            <XAxis
              axisLine={false}
              tick={{ fontSize: 12, fontWeight: 600 }}
              tickMargin={20}
              tickSize={0}
              dataKey="label"
            />
            <YAxis
              interval="preserveStartEnd"
              tick={{
                textAnchor: 'start',
                position: 'left',
                fontSize: 12,
                fontWeight: 600,
              }}
              tickFormatter={valueFormatter}
              tickMargin={38}
              tickSize={0}
              axisLine={false}
            />
            {mortgageeLines.map((line, index) => (
              <Line
                key={index}
                isAnimationActive={false}
                dataKey={line.id}
                stroke={mappedColorData[line.id]}
                strokeWidth="4"
                dot={{ r: 6 }}
              />
            ))}
            <Tooltip
              content={
                <LineChartTooltip
                  valueFormatter={valueFormatter}
                  colorMappings={mappedColorData}
                  nameMappings={nameMapping}
                />
              }
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default LSLineChart
