import { apiStateTemplate, generateApiReducers } from 'src/store/apiHelper'
import { createSlice } from '@reduxjs/toolkit'
import {
  manuallySetActiveWithGeo,
  setActiveChild,
  setActiveChildFromGeo,
  setCollectionActive,
  setCurrentViewType,
  updateViewType,
} from 'src/store/explore/actions'
import { ExploreState, ViewType } from 'src/store/explore/model'

const initialState = <ExploreState>{
  activeArea: null,
  currentViewType: ViewType.Statistical,
  ...apiStateTemplate,
}

export const exploreSlice = createSlice({
  name: 'explore',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    const createApiReducer = generateApiReducers(builder)
    createApiReducer(setCollectionActive, (state: ExploreState, action) => {
      state.activeArea = action.payload
    })

    createApiReducer(
      manuallySetActiveWithGeo,
      (state: ExploreState, action) => {
        state.activeArea = action.payload
      }
    )
    createApiReducer(setActiveChild, (state: ExploreState, action) => {
      state.activeArea = action.payload
    })
    createApiReducer(setActiveChildFromGeo, (state: ExploreState, action) => {
      state.activeArea = action.payload
    })

    builder.addCase(setCurrentViewType, (state: ExploreState, action) => {
      state.currentViewType = action.payload
    })
    createApiReducer(updateViewType, null)
  },
})
