import { createAsyncThunk } from '@reduxjs/toolkit'
import { endpointUrl } from 'src/store/apiHelper'
import authenticatedFetch from 'src/utils/authenticatedFetch'

export const getCollection = createAsyncThunk(
  'collections/getCollection',
  async (query: { collectionId: string }) => {
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${query.collectionId}`
    )
    return await response.json()
  }
)

export const getAllCollections = createAsyncThunk(
  'collections/getAllCollections',
  async () => {
    const response = await authenticatedFetch(`${endpointUrl}/collection`)
    return await response.json()
  }
)
