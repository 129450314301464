interface UserAttributes {
  sub: string
  email: string
  given_name: string
  family_name: string
  email_verified: boolean
}

interface UserTokens {
  idToken: string
  accessToken: string
  refreshToken: string
}

export interface User {
  tokens: UserTokens
  attributes: UserAttributes
}

export enum SessionStatus {
  Valid = 'valid',
  Expired = 'expired',
  PendingPassword = 'pending_password',
  ResetRequired = 'reset_required',
  Initialising = 'initialising',
}

export interface AuthContext {
  user: User | null
  isLoggedIn: boolean
  status: SessionStatus
  logout: () => Promise<void>
  confirmPassword: (
    newPassword: string,
    firstName: string,
    lastName: string
  ) => Promise<void>
  changePassword: (tempPassword: string, newPassword: string) => Promise<void>
  login: (email: string, password: string) => Promise<void>
  resetPassword: (username: string) => Promise<void>
  confirmPasswordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>
}
