import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { apiStateTemplate, generateApiReducers } from 'src/store/apiHelper'
import {
  getAllPostcodes,
  getPostcode,
  getAllSuburbs,
  getSuburb,
} from 'src/store/geographicalAreas/actions'
import {
  GeographicalArea,
  GeographicalParents,
} from 'src/store/geographicalAreas/model'
import { BaseArea } from 'src/store/sharedModels'

export const geographicalAreaAdapter = createEntityAdapter<GeographicalArea>({
  selectId: area => area.id,
})

const createGeographicalArea = (data: BaseArea, parents: GeographicalParents) =>
  <GeographicalArea>{ ...data, parents }

export const geographicalAreasSlice = createSlice({
  name: 'geographicalAreas',
  initialState: geographicalAreaAdapter.getInitialState(apiStateTemplate),
  reducers: {},
  extraReducers: builder => {
    const createApiReducer = generateApiReducers(builder)

    createApiReducer(getPostcode, (state, action) => {
      geographicalAreaAdapter.upsertOne(
        state,
        createGeographicalArea(action.payload.data.data, action.payload.ids)
      )
    })
    createApiReducer(getAllPostcodes, (state, action) => {
      geographicalAreaAdapter.upsertMany(
        state,
        action.payload.data.data.statisticalAreas.map((area: BaseArea) =>
          createGeographicalArea(area, action.payload.ids)
        )
      )
    })

    createApiReducer(getSuburb, (state, action) => {
      geographicalAreaAdapter.upsertOne(
        state,
        createGeographicalArea(action.payload.data.data, action.payload.ids)
      )
    })
    createApiReducer(getAllSuburbs, (state, action) => {
      geographicalAreaAdapter.upsertMany(
        state,
        action.payload.data.data.statisticalAreas.map((area: BaseArea) =>
          createGeographicalArea(area, action.payload.ids)
        )
      )
    })
  },
})
