import styled, { css } from 'styled-components'
import LoadingButton from 'src/components/inputs/LoadingButton'
import { string } from 'yup'

export const Container = styled.div(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    padding-top: ${theme.spacing(8)}px;
    background-color: ${theme.palette.greys.pale};
  `
)

const RootContainer = styled.div(
  ({ theme }) => css`
    width: 485px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: ${theme.spacing(2, 2.5)};
    margin: ${theme.spacing(8, 0, 2)};
    color: ${theme.palette.error.main};
    background-color: ${theme.palette.error.light};

    svg {
      width: 18px;
      margin: ${theme.spacing(0, 1, 0, 0)};
    }
  `
)

export const ErrorContainer = styled(RootContainer)(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    background-color: ${theme.palette.error.light};
  `
)

export const SuccessContainer = styled(RootContainer)(
  ({ theme }) => css`
    color: ${theme.palette.success.main};
    background-color: ${theme.palette.success.light};
  `
)

export const Form = styled.form<{ hasError?: boolean }>(
  ({ theme, hasError = false }) => css`
    z-index: 2;
    width: 485px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    background-color: #fff;
    padding: ${theme.spacing(5, 6)};
    box-shadow: ${theme.shadows[1]};
    margin: ${hasError ? '0px' : theme.spacing(8, 0, 0)};

    h2 {
      margin: ${theme.spacing(0, 0, 3)};
    }
  `
)

export const DummyForm = styled.div<{ hasError?: boolean }>(
  ({ theme, hasError = false }) => css`
    width: 485px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    background-color: #fff;
    padding: ${theme.spacing(5, 6)};
    box-shadow: ${theme.shadows[1]};
    margin: ${hasError ? '0px' : theme.spacing(8, 0, 0)};

    h2 {
      margin: ${theme.spacing(0, 0, 3)};
    }
  `
)

export const SubmitButton = styled(LoadingButton)(
  ({ theme, loading }) => css`
    margin: ${theme.spacing(3, 0, 0)};

    svg {
      left: 34%;
      position: ${loading ? 'initial' : 'absolute'};
    }
  `
)

export const IllustrationContainer = styled.div`
  z-index: 1;
  bottom: -10px;
  width: 100vw;
  display: flex;
  position: absolute;
  justify-content: space-around;
`

export const IllustrationCornerContainer = styled.div`
  display: flex;
`

export const PasswordRequirements = string()
  .min(8, 'Your password must be at least 8 characters long')
  .matches(/[a-z]/, 'Must contain a lowercase character')
  .matches(/[A-Z]/, 'Must contain an uppercase character')
  //eslint-disable-next-line
  .matches(/[`~!@#$%^&*()_+={}|;:'",.<>?\\\/\-\[\]]/, 'Must contain a symbol')
  .matches(/\d/, 'Must contain a number')

export interface ChangePasswordState {
  firstName: string
  lastName: string
}
