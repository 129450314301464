import {
  AreaGrowthInsight,
  AreaPerformanceInsight,
  BlockInsight,
  ColumnChartInsight,
  Insight,
  InsightType,
  LineChartInsight,
  OverviewInsight,
} from 'src/store/insights/model'

export const mapKindToInsight = (rawInsight: Insight) => {
  switch (rawInsight.kind) {
    case InsightType.AreaPerformance:
      return rawInsight as AreaPerformanceInsight
    case InsightType.AreaGrowth:
      return rawInsight as AreaGrowthInsight
    case InsightType.Overview:
      return rawInsight as OverviewInsight
    case InsightType.Block:
      return rawInsight as BlockInsight
    case InsightType.ColumnChart:
      return rawInsight as ColumnChartInsight
    case InsightType.LineChart:
      return rawInsight as LineChartInsight
  }
}
