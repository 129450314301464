import { createAsyncThunk } from '@reduxjs/toolkit'
import { endpointUrl } from 'src/store/apiHelper'
import authenticatedFetch from 'src/utils/authenticatedFetch'

export const getSA2 = createAsyncThunk(
  'statisticalAreas/getSA2',
  async (query: { collectionId: string; sa2Id: string }) => {
    const { collectionId, sa2Id } = query
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${collectionId}/sa2/${sa2Id}`
    )
    const data = await response.json()
    return { data, ids: { collectionId } }
  }
)

export const getAllSA2s = createAsyncThunk(
  'statisticalAreas/getAllSA2s',
  async (query: { collectionId: string }) => {
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${query.collectionId}/sa2`
    )
    const data = await response.json()
    return { data, ids: query }
  }
)

export const getSA1 = createAsyncThunk(
  'statisticalAreas/getSA1',
  async (query: { collectionId: string; sa2Id: string; sa1Id: string }) => {
    const { collectionId, sa2Id, sa1Id } = query
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${collectionId}/sa2/${sa2Id}/sa1/${sa1Id}`
    )
    const data = await response.json()
    return { data, ids: { collectionId, sa2Id } }
  }
)

export const getAllSA1s = createAsyncThunk(
  'statisticalAreas/getAllSA1s',
  async (query: { collectionId: string; sa2Id: string }) => {
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${query.collectionId}/sa2/${query.sa2Id}/sa1`
    )
    const data = await response.json()
    return { data, ids: query }
  }
)

export const getMeshblock = createAsyncThunk(
  'statisticalAreas/getMeshblock',
  async (query: {
    collectionId: string
    sa2Id: string
    sa1Id: string
    meshblockId: string
  }) => {
    const { collectionId, sa2Id, sa1Id, meshblockId } = query
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${collectionId}/sa2/${sa2Id}/sa1/${sa1Id}/meshblock/${meshblockId}`
    )
    const data = await response.json()
    return { data, ids: { collectionId, sa2Id, sa1Id } }
  }
)

export const getAllMeshblocks = createAsyncThunk(
  'statisticalAreas/getAllMeshblocks',
  async (query: { collectionId: string; sa2Id: string; sa1Id: string }) => {
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${query.collectionId}/sa2/${query.sa2Id}/sa1/${query.sa1Id}/meshblock`
    )
    const data = await response.json()
    return { data, ids: query }
  }
)
