import { Zap } from 'react-feather'
import { useMemo, useState, MouseEvent } from 'react'

import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableContainer,
} from '@material-ui/core'

import { useHistory } from 'react-router'
import { AreaType } from 'src/store/explore/model'
import {
  EndCell,
  IconButton,
} from 'src/components/Views/Explore/DataTable/common'
import { Routes, ExploreTabs } from 'src/models/routes'
import { Stats, BaseArea } from 'src/store/sharedModels'
import { resolvePath, trackEvent } from 'src/utils'
import styled from 'styled-components'

const HoverRow = styled(TableRow)`
  cursor: pointer;
`

export interface StatisticalAreaColumn<T> {
  field: string
  sortField?: T
  minWidth: number
  headerName: string
  renderCell?: (stats: Stats) => JSX.Element
}

interface Props<T extends string> {
  defaultSortKey: T
  activeType: AreaType | null
  isSuburbActive: boolean
  columns: StatisticalAreaColumn<T>[]
  data: BaseArea[] | undefined
  createActiveAreaSetter: (area: BaseArea) => () => void
}

type Order = 'asc' | 'desc'

const StatisticalAreaTable = <T extends string>({
  data,
  columns,
  activeType,
  defaultSortKey,
  isSuburbActive,
  createActiveAreaSetter,
}: Props<T>): JSX.Element => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<T>(defaultSortKey)

  const history = useHistory()

  const createSortRequestHandler = (columnId: T) => () => {
    const isAsc = orderBy === columnId && order === 'asc'
    const newOrder: Order = isAsc ? 'desc' : 'asc'

    const column = columns.find(item => item.sortField === columnId)?.headerName

    setOrder(newOrder)
    setOrderBy(columnId)
    trackEvent('mhs_web_list_sort', { column, order: newOrder })
  }

  const createRowClickHandler = (row: BaseArea) => () => {
    // Reset ordering when new data is loading into the table
    setOrder('asc')
    setOrderBy(defaultSortKey)

    // Call the function createActiveAreaSetter creates
    createActiveAreaSetter(row)()
  }

  const createIconLink = (row: BaseArea, route: string) => (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation()

    if (route.includes(ExploreTabs.Map)) {
      trackEvent('mhs_web_heatmap', {
        reference: 'icon',
        level: activeType,
      })
    } else if (route.includes(ExploreTabs.Insights)) {
      trackEvent('mhs_web_insight', {
        reference: 'icon',
        level: activeType,
      })
    }

    // Call the function createActiveAreaSetter creates
    createActiveAreaSetter(row)()
    history.push(route)
  }

  const sortedData = useMemo(() => {
    if (!orderBy) {
      return data
    }

    const isAsc = order === 'asc'

    return data?.sort((a, b) => {
      const aValue = resolvePath(orderBy, a)
      const bValue = resolvePath(orderBy, b)

      if (aValue === bValue) {
        return 0
      }

      if (isAsc) {
        return aValue > bValue ? 1 : -1
      }

      return aValue > bValue ? -1 : 1
    })
  }, [data, order, orderBy])

  return (
    <div className="tbl-view-bottom">
      <TableContainer className="tbl-view-tbl-block">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.sortField}
                  style={{ minWidth: column.minWidth }}
                  sortDirection={orderBy === column.sortField ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.sortField}
                    onClick={createSortRequestHandler(column.sortField as T)}
                    direction={orderBy === column.sortField ? order : 'asc'}
                  >
                    {column.headerName}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map(row => (
              <HoverRow
                hover
                key={row.id}
                tabIndex={-1}
                role="checkbox"
                onClick={createRowClickHandler(row)}
              >
                {columns.map(column => {
                  const value = resolvePath(column.field, row)
                  return (
                    <TableCell key={column.field}>
                      {column.renderCell ? column.renderCell(value) : value}
                    </TableCell>
                  )
                })}
                <EndCell>
                  <Box display="flex" justifyContent="space-around">
                    {/* TODO enabled when we can select an area on the map from navigation like this */}
                    {/*<IconButton*/}
                    {/*  aria-label="Map view"*/}
                    {/*  onClick={createIconLink(*/}
                    {/*    row,*/}
                    {/*    Routes.Explore + ExploreTabs.Map*/}
                    {/*  )}*/}
                    {/*>*/}
                    {/*  <Map />*/}
                    {/*</IconButton>*/}
                    {!isSuburbActive && (
                      <IconButton
                        aria-label="Insights"
                        onClick={createIconLink(
                          row,
                          Routes.Explore + ExploreTabs.Insights
                        )}
                      >
                        <Zap />
                      </IconButton>
                    )}
                  </Box>
                </EndCell>
              </HoverRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default StatisticalAreaTable
