import { object, string } from 'yup'
import { XCircle } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import { PlayCircle } from 'react-feather'
import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import { Box, Typography } from '@material-ui/core'

import {
  Form,
  Container,
  SubmitButton,
  ErrorContainer,
  SuccessContainer,
  IllustrationContainer,
  IllustrationCornerContainer,
} from 'src/modules/auth/common'
import version from 'src/version'
import useAuth from 'src/hooks/useAuth'
import { Routes } from 'src/models/routes'
import FormTextField from 'src/components/inputs/FormTextField'

import plantsLeft from 'src/Images/plants-bottom-left.svg'
import plantsRight from 'src/Images/plants-bottom-right.svg'
import { ReactComponent as InsightfulLogo } from 'src/Images/insightful-logo.svg'

const loginSchema = object().shape({
  email: string()
    .required('Please enter your email')
    .email('Please enter a valid email address'),
  password: string().required('Please enter your password'),
})

const Login: React.FC = () => {
  const [submitError, setSubmitError] = useState('')

  const history = useHistory()
  const { state } = useLocation<{ success: string | null }>()
  const { login, isLoggedIn } = useAuth()

  const { control, errors, formState, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(loginSchema),
    defaultValues: { email: '', password: '' },
  })
  const { isSubmitting } = formState

  const onSubmit = handleSubmit(async ({ email, password }) => {
    try {
      await login(email, password)
    } catch (error) {
      setSubmitError(error.message)
    }
  })

  useEffect(() => {
    if (isLoggedIn) {
      history.push(Routes.Explore)
    }
  }, [history, isLoggedIn])

  return (
    <Container>
      <InsightfulLogo />
      {!!submitError && (
        <ErrorContainer>
          <XCircle />
          <Typography variant="subtitle1">{submitError}</Typography>
        </ErrorContainer>
      )}
      {!submitError && !!state?.success && (
        <SuccessContainer>
          <XCircle />
          <Typography variant="subtitle1">{state?.success}</Typography>
        </SuccessContainer>
      )}
      <Form hasError={!!submitError} onSubmit={onSubmit}>
        <Typography variant="h4">Log in to your account</Typography>
        <FormTextField
          fullWidth
          name="email"
          label="Email"
          errors={errors}
          id="login-email"
          control={control}
          variant="outlined"
          placeholder="Enter email"
        />
        <FormTextField
          fullWidth
          type="password"
          name="password"
          errors={errors}
          label="Password"
          control={control}
          variant="outlined"
          id="login-password"
          placeholder="Password"
        />
        <SubmitButton
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          loading={isSubmitting}
        >
          <PlayCircle />
          Login
        </SubmitButton>
      </Form>
      <IllustrationContainer>
        <IllustrationCornerContainer>
          <img src={plantsLeft} alt="PlantsLeft" />
        </IllustrationCornerContainer>
        <div />
        <IllustrationCornerContainer>
          <img src={plantsRight} alt="PlantsRight" />
        </IllustrationCornerContainer>
      </IllustrationContainer>
      <Box mt={1}>
        <Typography variant="caption">Version: {version}</Typography>
      </Box>
    </Container>
  )
}

export default Login
