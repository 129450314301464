import { useDispatch } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import React, { useEffect } from 'react'

import useAuth from 'src/hooks/useAuth'
// import Summary from 'src/modules/summary'
import Login from 'src/modules/auth/Login'
import { Routes } from 'src/models/routes'
import { init } from 'src/store/explore/actions'
import Explore from 'src/components/Views/Explore'
// import Reports from 'src/components/Views/Reports'
// import Collections from 'src/components/Views/Collections'
import PrivateRoute from 'src/components/navigation/PrivateRoute'
import Navbar from 'src/components/Navbar'
import ResetPassword from 'src/modules/auth/ResetPassword'
import ChangePassword from 'src/modules/auth/ChangePassword'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (isLoggedIn) dispatch(init())
  }, [dispatch, isLoggedIn])

  return (
    <div className="container">
      {isLoggedIn && <Navbar />}
      <Switch>
        <Route path={Routes.Login} exact>
          <Login />
        </Route>
        <Route path={Routes.ChangePassword} exact>
          <ChangePassword />
        </Route>
        <Route path={Routes.Reset} exact>
          <ResetPassword />
        </Route>
        <PrivateRoute path={Routes.Explore}>
          <Explore />
        </PrivateRoute>
        <Route path="/">
          <Redirect to={isLoggedIn ? Routes.Explore : Routes.Login} />
        </Route>
        {/* TODO: Enable once functionality is built */}
        {/* <PrivateRoute path={Routes.Reports} exact>
          <Reports />
        </PrivateRoute>
        <PrivateRoute path={Routes.Collections} exact>
          <Collections />
        </PrivateRoute>
        <PrivateRoute path={Routes.Summary} exact>
         <Summary />
        </PrivateRoute> */}
      </Switch>
    </div>
  )
}

export default App
