import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'

import { Box, Grid } from '@material-ui/core'

import { AreaType } from 'src/store/explore/model'
import { ExploreTabs, Routes } from 'src/models/routes'
import MapBlock from 'src/components/Views/Explore/LSMap'
import { trackEvent } from 'src/utils'
import DataTable from 'src/components/Views/Explore/DataTable'
import InsightView from 'src/components/Views/Explore/Insight'
import ExploreNavbar from 'src/components/Navbar/ExploreNavbar'
import { activeAreaTypeSelector } from 'src/store/explore/selectors'
import FloatingPanel from 'src/components/Views/Explore/FloatingPanel'

const Explore: React.FC = () => {
  const { pathname } = useLocation()

  const activeAreaType = useSelector(activeAreaTypeSelector)

  useEffect(() => {
    const handleCopyEvent = () => {
      let event = 'mhs_web_list_copy'

      if (pathname.includes(ExploreTabs.Map)) {
        event = 'mhs_web_heatmap_copy'
      } else if (pathname.includes(ExploreTabs.Insights)) {
        event = 'mhs_web_insight_copy'
      }

      trackEvent(event, {
        level: activeAreaType,
      })
    }

    document.addEventListener('copy', handleCopyEvent)

    return () => {
      document.removeEventListener('copy', handleCopyEvent)
    }
  }, [pathname, activeAreaType])

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ExploreNavbar />

      <Grid container style={{ height: '100%' }}>
        <Grid item md={2}>
          <FloatingPanel />
        </Grid>
        <Grid item md={10}>
          <div className="right-panel">
            <Route path={Routes.Explore + ExploreTabs.Map}>
              {({ match }) => <MapBlock visible={!!match} />}
            </Route>
            <Route path={Routes.Explore + ExploreTabs.Data}>
              {({ match }) => <DataTable visible={!!match} />}
            </Route>
            <Route path={Routes.Explore + ExploreTabs.Insights}>
              {({ match }) => <InsightView visible={!!match} />}
            </Route>
            <Route path={Routes.Explore}>
              <Redirect to={Routes.Explore + ExploreTabs.Map} />
            </Route>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Explore
