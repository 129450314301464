export enum Routes {
  Login = '/login',
  Reset = '/reset',
  ChangePassword = '/change-password',
  Explore = '/explore',
  Reports = '/reports',
  Collections = '/collections',
}

export enum ExploreTabs {
  Map = '/map',
  Data = '/list',
  Insights = '/insights',
}
