import { ApiState } from 'src/store/apiHelper'
import { GeographicalAreaType } from 'src/store/geographicalAreas/model'
import { StatisticalAreaType } from 'src/store/statisticalAreas/model'

export interface ExploreState extends ApiState {
  activeArea: ActiveArea | null
  currentViewType: ViewType
  hasError: boolean
}

export enum ViewType {
  Statistical = 'Statistical',
  Geographical = 'Geographical',
}

export type AreaType = StatisticalAreaType | GeographicalAreaType

export const getAreaSubType = (
  currentType: ViewType,
  type: AreaType | null
): AreaType | undefined => {
  switch (currentType) {
    case ViewType.Statistical:
      switch (type) {
        case StatisticalAreaType.Collection:
          return StatisticalAreaType.SA2
        case StatisticalAreaType.SA2:
          return StatisticalAreaType.SA1
        case StatisticalAreaType.SA1:
          return StatisticalAreaType.MeshBlock
      }
      break
    case ViewType.Geographical:
      switch (type) {
        case GeographicalAreaType.Collection:
          return GeographicalAreaType.Postcode
        case GeographicalAreaType.Postcode:
          return GeographicalAreaType.Suburb
      }
      break
  }
  return undefined
}

export interface ActiveArea {
  collectionId: string
  activeChildId?: string
}

export interface ActiveAreaPath {
  id: string
  name: string
}
