import { BaseArea } from 'src/store/sharedModels'

export interface StatisticalParents {
  [key: string]: string | null

  collectionId: string | null
  sa2Id: string | null
  sa1Id: string | null
}

export interface StatisticalArea extends BaseArea {
  parents: StatisticalParents
}

export enum StatisticalAreaType {
  Collection = 'Collection',
  SA1 = 'SA1',
  SA2 = 'SA2',
  MeshBlock = 'MeshBlock',
}

export interface StatisticalGeoParents {
  sa3: string
  sa2?: string
  sa1?: string
}
