import { MouseEvent } from 'react'
import { Zap } from 'react-feather'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from '@material-ui/core'

import { trackEvent, resolvePath, formatPercentage } from 'src/utils'
import {
  activeAreaStatsSelector,
  activeDataSelector,
  activeAreaTypeSelector,
  getCurrentViewType,
} from 'src/store/explore/selectors'
import TrendPill from 'src/components/Util/TrendPill'
import {
  EndCell,
  IconButton,
} from 'src/components/Views/Explore/DataTable/common'
import { ExploreTabs, Routes } from 'src/models/routes'
import { setActiveChild } from 'src/store/explore/actions'
import { Stats, BaseArea } from 'src/store/sharedModels'
import { getAreaSubType } from 'src/store/explore/model'
import StatisticalAreaTable, {
  StatisticalAreaColumn,
} from 'src/components/Views/Explore/DataTable/StatisticalAreaTable'
import { GeographicalAreaType } from 'src/store/geographicalAreas/model'

interface DataTableProps {
  visible: boolean
}

enum SortKeyEnum {
  Name = 'name',
  LotShare = 'stats.lot.share',
  MortgageCount = 'stats.mortgage.count',
  MortgageShare = 'stats.mortgage.share',
}

const TrendCellRenderer = (stats: Stats) => {
  return (
    <div>
      <span>{formatPercentage(stats.share)}</span>
      <TrendPill figure={stats.trend} />
    </div>
  )
}

const DataTable: React.FC<DataTableProps> = ({ visible }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const data = useSelector(activeDataSelector)
  const activeAreaStats = useSelector(activeAreaStatsSelector)
  const activeType = useSelector(activeAreaTypeSelector)
  const currentViewType = useSelector(getCurrentViewType)

  const isSuburbActive = activeType === GeographicalAreaType.Suburb

  const createActiveAreaSetter = (area: BaseArea) => () => {
    dispatch(setActiveChild(area.id))

    trackEvent('mhs_web_list', {
      reference: 'list',
      level: activeType,
    })
  }

  const createIconLink = (route: string) => (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation()

    if (route.includes(ExploreTabs.Map)) {
      trackEvent('mhs_web_heatmap', {
        reference: 'icon',
        level: activeType,
      })
    } else if (route.includes(ExploreTabs.Insights)) {
      trackEvent('mhs_web_insight', {
        reference: 'icon',
        level: activeType,
      })
    }

    history.push(route)
  }

  const columns: StatisticalAreaColumn<SortKeyEnum>[] = [
    {
      minWidth: 150,
      headerName: 'My Mortgages',
      field: 'stats.mortgage.count',
      sortField: SortKeyEnum.MortgageCount,
    },
    {
      minWidth: 150,
      field: 'stats.lot',
      headerName: 'Lot Share',
      renderCell: TrendCellRenderer,
      sortField: SortKeyEnum.LotShare,
    },
    {
      minWidth: 200,
      field: 'stats.mortgage',
      renderCell: TrendCellRenderer,
      headerName: 'Mortgage Share (Vol)',
      sortField: SortKeyEnum.MortgageShare,
    },
  ]

  const topColumns: StatisticalAreaColumn<SortKeyEnum>[] = [
    {
      field: 'name',
      minWidth: 150,
      headerName: activeType?.toString() ?? '',
    },
    ...columns,
  ]

  const bottomColumns: StatisticalAreaColumn<SortKeyEnum>[] = [
    {
      field: 'name',
      minWidth: 150,
      sortField: SortKeyEnum.Name,
      headerName: getAreaSubType(currentViewType, activeType)?.toString() ?? '',
    },
    ...columns,
  ]

  return (
    <div className={`tbl-view ${visible ? 'showme' : 'hideme'}`}>
      <div className="tbl-view-top">
        {activeAreaStats != null && activeAreaStats.stats != null && (
          <TableContainer className="tbl-view-tbl-block">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {topColumns.map(column => (
                    <TableCell
                      key={column.field}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}
                  <EndCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow role="checkbox" tabIndex={-1}>
                  {topColumns.map(column => {
                    const value = resolvePath(column.field, activeAreaStats)
                    return (
                      <TableCell key={column.field}>
                        {column.renderCell ? column.renderCell(value) : value}
                      </TableCell>
                    )
                  })}
                  <EndCell>
                    <Box display="flex" justifyContent="space-around">
                      {/* TODO enabled when we can select an area on the map from navigation like this */}
                      {/*<IconButton*/}
                      {/*  onClick={createIconLink(*/}
                      {/*    Routes.Explore + ExploreTabs.Map*/}
                      {/*  )}*/}
                      {/*  aria-label="Map view"*/}
                      {/*>*/}
                      {/*  <Map />*/}
                      {/*</IconButton>*/}
                      {(isSuburbActive ||
                        activeType === GeographicalAreaType.Postcode) && (
                        <IconButton
                          onClick={createIconLink(
                            Routes.Explore + ExploreTabs.Insights
                          )}
                          aria-label="Insights"
                        >
                          <Zap />
                        </IconButton>
                      )}
                    </Box>
                  </EndCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {!!data?.length && (
        <StatisticalAreaTable<SortKeyEnum>
          data={data}
          activeType={activeType}
          columns={bottomColumns}
          isSuburbActive={isSuburbActive}
          defaultSortKey={SortKeyEnum.LotShare}
          createActiveAreaSetter={createActiveAreaSetter}
        />
      )}
    </div>
  )
}

export default DataTable
