import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { apiStateTemplate, generateApiReducers } from 'src/store/apiHelper'

import {
  getSA2,
  getAllSA2s,
  getSA1,
  getAllSA1s,
  getMeshblock,
  getAllMeshblocks,
} from 'src/store/statisticalAreas/actions'
import {
  StatisticalArea,
  StatisticalParents,
} from 'src/store/statisticalAreas/model'
import { BaseArea } from 'src/store/sharedModels'

export const statisticalAreasAdapter = createEntityAdapter<StatisticalArea>({
  selectId: area => area.id,
})

const createStatisticalArea = (data: BaseArea, parents: StatisticalParents) =>
  <StatisticalArea>{ ...data, parents }

export const statisticalAreasSlice = createSlice({
  name: 'statisticalAreas',
  initialState: statisticalAreasAdapter.getInitialState(apiStateTemplate),
  reducers: {},
  extraReducers: builder => {
    const createApiReducer = generateApiReducers(builder)

    createApiReducer(getSA2, (state, action) => {
      statisticalAreasAdapter.upsertOne(
        state,
        createStatisticalArea(action.payload.data.data, action.payload.ids)
      )
    })
    createApiReducer(getAllSA2s, (state, action) => {
      statisticalAreasAdapter.upsertMany(
        state,
        action.payload.data.data.statisticalAreas.map((area: BaseArea) =>
          createStatisticalArea(area, action.payload.ids)
        )
      )
    })

    createApiReducer(getSA1, (state, action) => {
      statisticalAreasAdapter.upsertOne(
        state,
        createStatisticalArea(action.payload.data.data, action.payload.ids)
      )
    })
    createApiReducer(getAllSA1s, (state, action) => {
      statisticalAreasAdapter.upsertMany(
        state,
        action.payload.data.data.statisticalAreas.map((area: BaseArea) =>
          createStatisticalArea(area, action.payload.ids)
        )
      )
    })

    createApiReducer(getMeshblock, (state, action) => {
      statisticalAreasAdapter.upsertOne(
        state,
        createStatisticalArea(action.payload.data.data, action.payload.ids)
      )
    })
    createApiReducer(getAllMeshblocks, (state, action) => {
      statisticalAreasAdapter.upsertMany(
        state,
        action.payload.data.data.statisticalAreas.map((area: BaseArea) =>
          createStatisticalArea(area, action.payload.ids)
        )
      )
    })
  },
})
