export interface InsightBoxProps {
  heading: string
  type: string
  insightText: string[]
}

const InsightBox: React.FC<InsightBoxProps> = ({
  heading,
  type,
  insightText,
}) => (
  <div className="insight-box">
    <span className="tag">INSIGHT</span>
    <div className="heading">
      {heading.replace(/\b\w/g, l => l.toUpperCase())}
    </div>
    <div className="insight-body">
      {insightText.map((insightItem: string, index: number) => (
        <p key={index}>{insightItem}</p>
      ))}
    </div>
  </div>
)

export default InsightBox
