import { RouteProps, Route, Redirect } from 'react-router-dom'

import useAuth from 'src/hooks/useAuth'
import { Routes } from 'src/models/routes'
import { SessionStatus } from 'src/models/auth'
import LoadingApp from 'src/components/dataDisplay/LoadingApp'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { status, isLoggedIn } = useAuth()

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoggedIn) {
          return children
        }

        return status === SessionStatus.Initialising ? (
          <LoadingApp />
        ) : (
          <Redirect
            to={{
              pathname: Routes.Login,
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
