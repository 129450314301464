import { apiStateTemplate, generateApiReducers } from 'src/store/apiHelper'
import { createSlice } from '@reduxjs/toolkit'
import { Mortgagee, MortgageesState } from 'src/store/mortgagees/model'
import { getAllMortgagees } from 'src/store/mortgagees/actions'

const initialState = <MortgageesState>{
  mortgagees: [],
  you: null,
  ...apiStateTemplate,
}

export const mortgageesSlice = createSlice({
  name: 'mortgagees',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    const createApiReducer = generateApiReducers(builder)
    createApiReducer(getAllMortgagees, (state: MortgageesState, action) => {
      state.mortgagees = action.payload.data.mortgagees
      state.you = action.payload.data.mortgagees.find(
        (mortgagee: Mortgagee) => mortgagee.isCustomer
      )
    })
  },
})
