import { Box, Grid, Popover, Typography } from '@material-ui/core'
import { Info, List, Map } from 'react-feather'
import { formatPercentage } from 'src/utils'
import {
  AreaGrowthInsight,
  AreaPerformanceInsight,
  InsightType,
  InterestCard,
} from 'src/store/insights/model'
import { MouseEventHandler, useState } from 'react'
import AreaStatsPopover from 'src/components/dataDisplay/AreaStatsPopover'
import { GeographicalAreaType } from 'src/store/geographicalAreas/model'
import { Metric, ServerMetric } from 'src/store/map/model'
import styled, { css } from 'styled-components'
import { toWords } from 'number-to-words'
import { ExploreTabs } from 'src/models/routes'
import { useDispatch } from 'react-redux'
import { manuallySetActiveWithGeo } from 'src/store/explore/actions'

const AreaCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 25px 35px -10px rgba(102, 73, 28, 0.07);
  border-radius: 20px;
  padding: 20px;
  width: 340px;
  max-width: 340px;
`

interface ShareValueProps {
  readonly $negative: boolean
}

const CardTypography = styled(Typography)`
  margin-top: 12px;
`

const ShareValueTypography = styled(CardTypography)<ShareValueProps>(
  ({ theme, $negative }) => css`
    color: ${$negative ? theme.palette.error.main : theme.palette.success.main};
  `
)

const Subtext = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.greys.mediumDark};
  `
)

interface AreaCardProps {
  card: InterestCard
  isNegative: boolean
  onMouseLeave: MouseEventHandler
  onInfoHover: (element: Element) => void
}

const AreaCard: React.FC<AreaCardProps> = ({
  card,
  isNegative,
  onMouseLeave,
  onInfoHover,
}) => {
  const { name, total, stats, metric } = card

  let statData
  let totalValue
  switch (metric) {
    case ServerMetric.LotShare:
      statData = stats.lot
      totalValue = total.lots
      break
    case ServerMetric.MortgageShare:
      statData = stats.mortgage
      totalValue = total.mortgages
      break
  }

  const metricDisplay = ((): Metric => {
    switch (metric) {
      case ServerMetric.LotShare:
        return Metric.LotShare
      case ServerMetric.MortgageShare:
        return Metric.MortgageShare
    }
  })()

  return (
    <AreaCardContainer>
      <Box display="flex" justifyContent="space-between">
        <Typography
          style={{ fontWeight: 700 }}
          variant="body2"
          color="textSecondary"
        >
          {name}
        </Typography>
        <Info
          color="#A0A0A0"
          onMouseLeave={onMouseLeave}
          onMouseEnter={event => {
            onInfoHover(event.currentTarget)
          }}
        />
      </Box>
      <ShareValueTypography variant="h2" $negative={isNegative}>
        {formatPercentage(statData.share)}
      </ShareValueTypography>
      <CardTypography variant="body1">
        {statData.count} of {totalValue} {metricDisplay} in market
      </CardTypography>
    </AreaCardContainer>
  )
}

interface AreaPerformanceProps {
  insight: AreaPerformanceInsight | AreaGrowthInsight
}

const copy = (type: InsightType, areaCount: number) => {
  switch (type) {
    case InsightType.AreaPerformance:
      return {
        title: 'Performance',
        subtitle: `Within this collection, these are the ${toWords(
          areaCount
        )} most underperforming suburbs.`,
        subtext:
          'These areas may require action to retain business and improve performance.',
      }
    case InsightType.AreaGrowth:
      return {
        title: 'Growth',
        subtitle: `Within this collection, these are the ${toWords(
          areaCount
        )} most outperforming suburbs.`,
        subtext:
          'Investigate success factors to apply lessons to other areas of your portfolio, why are the competition not growing as well?',
      }
  }
}

export const CardsInsight: React.FC<AreaPerformanceProps> = ({ insight }) => {
  const dispatch = useDispatch()
  const insightCopy = copy(insight.kind, insight.cards.length)

  const [activeCard, setActiveCard] = useState<InterestCard | null>()
  const [openedPopover, setOpenedPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const popoverEnter = () => {
    setOpenedPopover(true)
  }

  const popoverLeave = () => {
    setOpenedPopover(false)
  }

  const onNavigate = () => {
    if (activeCard == null) return
    setOpenedPopover(false)

    const { id, parents } = activeCard
    dispatch(manuallySetActiveWithGeo({ id, parents }))
  }

  const popoverNavButtons = [
    {
      tab: ExploreTabs.Data,
      display: (
        <>
          <List />
          View List
        </>
      ),
    },
    // TODO enabled when we can select an area on the map from navigation like this
    // {
    //   tab: ExploreTabs.Map,
    //   display: (
    //     <>
    //       <Map />
    //       Map View
    //     </>
    //   ),
    // },
  ]

  const isReversed = insight.kind === InsightType.AreaGrowth
  return (
    <div>
      <Grid
        container
        justify="space-around"
        direction={isReversed ? 'row-reverse' : 'row'}
      >
        <Grid item md={4} xs={12}>
          {insightCopy && (
            <>
              <Typography variant="h2">{insightCopy.title}</Typography>
              <Box my="1.4rem">
                <Typography variant="h4" component="h3">
                  {insightCopy.subtitle}
                </Typography>
              </Box>
              <Subtext variant="body1">{insightCopy.subtext}</Subtext>
              <Subtext variant="body1" style={{ marginTop: 10 }}>
                Click on the <i>info</i> icon for more information.
              </Subtext>
            </>
          )}
        </Grid>
        <Grid
          container
          item
          md={7}
          lg={6}
          xs={12}
          justify={isReversed ? 'flex-end' : 'flex-start'}
          spacing={3}
        >
          {insight.cards.map(card => (
            <Grid item key={card.id}>
              <AreaCard
                card={card}
                isNegative={!isReversed}
                onMouseLeave={popoverLeave}
                onInfoHover={element => {
                  setAnchorEl(element)
                  setActiveCard(card)
                  popoverEnter()
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Popover
        id="mouse-over-popover"
        open={openedPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
          style: { pointerEvents: 'auto' },
        }}
        style={{ pointerEvents: 'none' }}
      >
        {activeCard && (
          <AreaStatsPopover
            contentOnly
            totals={activeCard.total}
            location={activeCard.name}
            activeType={GeographicalAreaType.Suburb}
            stats={activeCard.stats}
            onNavigate={onNavigate}
            navTabs={popoverNavButtons}
          />
        )}
      </Popover>
    </div>
  )
}

export default CardsInsight
