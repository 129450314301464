import { ApiState } from 'src/store/apiHelper'
import { FeatureCollection, MultiPolygon, Polygon } from 'geojson'
import { StatMortgagee, Totals } from 'src/store/sharedModels'
import { NESWBounds } from 'google-map-react'
import { GeographicalGeoParents } from 'src/store/geographicalAreas/model'
import { StatisticalGeoParents } from 'src/store/statisticalAreas/model'

export type BoundsTable = { [key: string]: NESWBounds }

export interface MapState extends ApiState {
  geo: FeatureCollection[]
  bounds: BoundsTable
  activeMetric: Metric
}

export enum Metric {
  LotShare = 'Lot Share',
  MortgageShare = 'Mortgage Share',
}

export enum ServerMetric {
  LotShare = 'LOT_SHARE',
  MortgageShare = 'MORTGAGE_SHARE',
}

export type GeoParents = GeographicalGeoParents | StatisticalGeoParents

export interface GeoArea {
  id: string
  name: string
  parents: GeoParents
  stats: StatMortgagee[]
  geo: Polygon | MultiPolygon
  total: Totals
  bounds: NESWBounds
  ranks: {
    lotShare: number
    mortgageShare: number
  }
}
