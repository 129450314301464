import { createContext } from 'react'
import useProvideAuth from 'src/hooks/useProvideAuth'
import { AuthContext } from 'src/models/auth'

export const authContext = createContext<AuthContext | null>(null)

const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export default ProvideAuth
