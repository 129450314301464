import { RootState } from 'src/store/rootReducer'
import {
  ActiveArea,
  ActiveAreaPath,
  AreaType,
  ViewType,
} from 'src/store/explore/model'
import { statisticalAreasSelectors } from 'src/store/statisticalAreas/selectors'
import { geographicalAreaSelectors } from 'src/store/geographicalAreas/selectors'
import { getStatisticalAreaType } from 'src/store/statisticalAreas/utils'
import { hierarchySwitch } from 'src/store/explore/utils'
import {
  StatisticalArea,
  StatisticalAreaType,
} from 'src/store/statisticalAreas/model'
import {
  GeographicalArea,
  GeographicalAreaType,
} from 'src/store/geographicalAreas/model'
import { getGeographicalAreaType } from 'src/store/geographicalAreas/util'
import { Collection } from 'src/store/collection/model'
import { collectionSelectors } from 'src/store/collection/selectors'
import { EntityId } from '@reduxjs/toolkit'

export const activeAreaSelector = (state: RootState): ActiveArea | null =>
  state.explore.activeArea

export const activeCollectionSelector = (
  state: RootState
): Collection | undefined => {
  const collectionId = state.explore.activeArea?.collectionId
  if (collectionId == null) return undefined
  return collectionSelectors.selectById(state, collectionId)
}

export const insightsActiveSelector = (state: RootState): boolean => {
  const activeType = activeAreaTypeSelector(state)
  if (activeType == null) return false

  switch (activeType) {
    case StatisticalAreaType.Collection:
    case StatisticalAreaType.SA1:
    case StatisticalAreaType.MeshBlock:
      return false
    default:
      return true
  }
}

export const activeAreaTypeSelector = (state: RootState): AreaType | null => {
  return hierarchySwitch<AreaType>(
    state,
    statArea => getStatisticalAreaType(statArea),
    geoArea => getGeographicalAreaType(geoArea),
    () => {
      switch (getCurrentViewType(state)) {
        case ViewType.Statistical:
          return StatisticalAreaType.Collection
        case ViewType.Geographical:
          return GeographicalAreaType.Collection
      }
    }
  )
}

export const activeAreaNamedSelector = (state: RootState): ActiveAreaPath[] => {
  const nameTransform = (
    area: StatisticalArea | GeographicalArea,
    selector: (
      state: RootState,
      id: EntityId
    ) => StatisticalArea | GeographicalArea | undefined
  ) =>
    Object.values(area.parents)
      .filter(id => id != null)
      .map((id: any) => selector(state, id))
      .filter(area => area != undefined)
      .map(area => <ActiveAreaPath>{ id: area?.id, name: area?.name })
      .concat([<ActiveAreaPath>{ id: area.id, name: area.name }])

  return (
    hierarchySwitch<ActiveAreaPath[]>(
      state,
      statArea => {
        if (statArea == undefined) return []
        return nameTransform(statArea, statisticalAreasSelectors.selectById)
      },
      geoArea => {
        if (geoArea == undefined) return []
        return nameTransform(geoArea, geographicalAreaSelectors.selectById)
      },
      () => {
        return []
      }
    ) ?? []
  )
}

export const activeAreaStatsSelector = (state: RootState) => {
  return hierarchySwitch<
    StatisticalArea | GeographicalArea | Collection | undefined
  >(
    state,
    statArea => statArea,
    geoArea => geoArea,
    collection => collection
  )
}

export const activeDataSelector = (state: RootState) => {
  const activeArea = state.explore.activeArea
  if (activeArea == null) return []

  const activeId = activeArea.activeChildId ?? activeArea.collectionId

  const viewType = getCurrentViewType(state)

  const filterIds = (parentIds: { [key: string]: string | null }) => {
    const ids = Object.values(parentIds).filter(id => id != null)
    return ids[ids.length - 1] === activeId
  }

  switch (viewType) {
    case ViewType.Statistical:
      return statisticalAreasSelectors
        .selectAll(state)
        .filter(area => filterIds(area.parents) && area.id != activeId)
    case ViewType.Geographical:
      return geographicalAreaSelectors
        .selectAll(state)
        .filter(area => filterIds(area.parents) && area.id != activeId)
  }
}

export const getCurrentViewType = (state: RootState): ViewType =>
  state.explore.currentViewType
