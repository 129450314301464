import { apiStateTemplate, generateApiReducers } from 'src/store/apiHelper'
import { createSlice } from '@reduxjs/toolkit'
import { loadCollectionMap, setActiveMetric } from 'src/store/map/actions'
import { MapState, Metric } from 'src/store/map/model'

const initialState = <MapState>{
  ...apiStateTemplate,
  bounds: {},
  activeMetric: Metric.MortgageShare,
}

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    const createApiReducer = generateApiReducers(builder)
    createApiReducer(loadCollectionMap, (state: MapState, action) => {
      state.geo = action.payload.geo
      state.bounds = action.payload.bounds
    })
    builder.addCase(setActiveMetric, (state: MapState, action) => {
      state.activeMetric = action.payload
    })
  },
})
