import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'

import { Routes } from 'src/models/routes'
import { AuthContext, SessionStatus, User } from 'src/models/auth'
import { ChangePasswordState } from 'src/modules/auth/common'
import { CognitoUser } from 'amazon-cognito-identity-js'

const useProvideAuth = (): AuthContext => {
  const [user, setUser] = useState<User | null>(null)
  const [status, setStatus] = useState<SessionStatus>(
    SessionStatus.Initialising
  )

  const [pendingUser, setPendingUser] = useState<any>(null)

  const history = useHistory()

  const isLoggedIn = !!user && status === SessionStatus.Valid

  const startSession = (user: CognitoUser | any) => {
    const { attributes, signInUserSession } = user
    setUser({
      attributes,
      tokens: {
        idToken: signInUserSession.idToken.jwtToken,
        refreshToken: signInUserSession.refreshToken.token,
        accessToken: signInUserSession.accessToken.jwtToken,
      },
    })
    setStatus(SessionStatus.Valid)
  }

  const login = async (email: string, password: string) => {
    try {
      const user = await Auth.signIn(email, password)

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setPendingUser(user)
        setStatus(SessionStatus.PendingPassword)

        const { family_name, given_name } = user.challengeParam.userAttributes
        history.push(Routes.ChangePassword, <ChangePasswordState>{
          firstName: given_name,
          lastName: family_name,
        })
        return
      }

      startSession(user)
    } catch (error) {
      if (error.code === 'PasswordResetRequiredException') {
        setStatus(SessionStatus.ResetRequired)
        history.push(Routes.Reset, { username: email })
      } else {
        setStatus(SessionStatus.Expired)
        throw error
      }
    }
  }

  const changePassword = async (tempPassword: string, newPassword: string) => {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, tempPassword, newPassword)
    startSession(user)
  }

  const confirmPassword = async (
    newPassword: string,
    firstName: string,
    lastName: string
  ) => {
    await Auth.completeNewPassword(pendingUser, newPassword, {
      given_name: firstName,
      family_name: lastName,
    })
    setPendingUser(null)
    const user = await Auth.currentAuthenticatedUser()
    startSession(user)
  }

  const resetPassword = async (username: string) => {
    await Auth.forgotPassword(username)
  }

  const confirmPasswordReset = async (
    username: string,
    code: string,
    newPassword: string
  ) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword)
    setStatus(SessionStatus.Initialising)
    history.push(Routes.Login, {
      success: 'Your password has been reset. Please login',
    })
  }

  // TODO: complete properly in logout ticket
  const logout = async () => {
    try {
      await Auth.signOut()
    } catch (error) {
      // Remove tokens manually if Auth.signOut throws an error
      localStorage.clear()
    } finally {
      setUser(null)
      setStatus(SessionStatus.Expired)
      history.push(Routes.Login)
    }
  }

  useEffect(() => {
    const getAuthAndUser = async () => {
      try {
        const results = await Promise.all([
          Auth.currentSession(),
          Auth.currentAuthenticatedUser(),
        ])
        const session = results[0]
        const { attributes } = results[1]

        setUser({
          attributes,
          tokens: {
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
            accessToken: session.getAccessToken().getJwtToken(),
          },
        })
        setStatus(SessionStatus.Valid)
      } catch {
        setStatus(SessionStatus.Expired)
        history.push(Routes.Login)
      }
    }

    getAuthAndUser()
  }, [history])

  return {
    user,
    login,
    confirmPassword,
    changePassword,
    resetPassword,
    confirmPasswordReset,
    logout,
    status,
    isLoggedIn,
  }
}

export default useProvideAuth
