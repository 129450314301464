import styled, { css } from 'styled-components'

import { CircularProgress } from '@material-ui/core'

import plantsRight from 'src/Images/plants-bottom-right.svg'
import plantsLeft from 'src/Images/plants-bottom-left.svg'
import { ReactComponent as InsightfulLogo } from 'src/Images/insightful-logo.svg'

const Container = styled.div(
  ({ theme }) => css`
    height: 100%;
    min-height: 750px;
    min-width: 500px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    padding-top: ${theme.spacing(8)}px;
    background-color: ${theme.palette.greys.pale};
  `
)

const Content = styled.div(
  ({ theme }) => css`
    height: auto;
    top: 50%;
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    transform: translateY(-50%);

    p {
      color: ${theme.palette.greys.dark};
    }

    svg {
      color: ${theme.palette.greys.dark};
    }
  `
)

const IllustrationContainer = styled.div`
  bottom: 0px;
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: space-between;
`
const IllustrationCornerContainer = styled.div`
  display: flex;
`

const LoadingApp: React.FC = () => (
  <Container>
    <InsightfulLogo />
    <Content>
      <CircularProgress thickness={6} />
      <p>Loading...</p>
    </Content>
    <IllustrationContainer>
      <IllustrationCornerContainer>
        <img src={plantsLeft} alt="PlantsLeft" />
      </IllustrationCornerContainer>
      <IllustrationCornerContainer>
        <img src={plantsRight} alt="PlantsRight" />
      </IllustrationCornerContainer>
    </IllustrationContainer>
  </Container>
)

export default LoadingApp
