import Amplify from 'aws-amplify'
import { Router } from 'react-router'
import { ThemeProvider } from 'styled-components'
import { Provider as ReduxProvider } from 'react-redux'

import { CssBaseline } from '@material-ui/core'
import { StylesProvider } from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'

import store from 'src/store'
import theme from 'src/styles/theme'
import history from 'src/utils/history'
import { config } from 'src/config/amplify'
import GlobalStyles from 'src/styles/GlobalStyles'
import AuthProvider from 'src/modules/auth/AuthProvider'

import 'src/styles/_master.scss'

Amplify.configure(config)

const Providers: React.FC = ({ children }) => (
  <ReduxProvider store={store}>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <GlobalStyles />
          <CssBaseline />
          <Router history={history}>
            <AuthProvider>{children}</AuthProvider>
          </Router>
        </StylesProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </ReduxProvider>
)

export default Providers
