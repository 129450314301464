import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { Collection } from 'src/store/collection/model'
import { BaseArea } from 'src/store/sharedModels'
import { apiStateTemplate, generateApiReducers } from 'src/store/apiHelper'
import { getAllCollections, getCollection } from 'src/store/collection/actions'

export const collectionAdapter = createEntityAdapter<Collection>({
  selectId: collection => collection.id,
})

export const collectionSlice = createSlice({
  name: 'collections',
  initialState: collectionAdapter.getInitialState(apiStateTemplate),
  reducers: {},
  extraReducers: builder => {
    const createApiReducer = generateApiReducers(builder)
    createApiReducer(getCollection, (state, action) => {
      collectionAdapter.upsertOne(state, action.payload.data)
    })
    createApiReducer(getAllCollections, (state, action) => {
      const payload = action.payload.data.collections.sort(
        (a: BaseArea, b: BaseArea) =>
          (a.name as string) > (b.name as string) ? 1 : -1
      )

      collectionAdapter.setAll(state, payload)
    })
  },
})
