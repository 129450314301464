interface StatBoxProps {
  heading: string
  stat: number | string
}

export const StatBox: React.FC<StatBoxProps> = ({ heading, stat }) => {
  return (
    <div className="stat-box">
      <div className="heading">{heading}</div>
      <div className="stat-number">{stat}</div>
    </div>
  )
}

export default StatBox
