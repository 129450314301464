import GoogleMapReact, { MapTypeStyle } from 'google-map-react'
import MapLegend from 'src/components/Views/Explore/LSMap/Legend'
import { useDispatch, useSelector } from 'react-redux'
import { GoogleMapsOverlay } from '@deck.gl/google-maps'
import { GeoJsonLayer } from '@deck.gl/layers'
import { useEffect, useRef, useState } from 'react'
import { PickInfo } from '@deck.gl'
import { Feature, FeatureCollection, Polygon } from 'geojson'
import { PathStyleExtension } from '@deck.gl/extensions'
import {
  activeAreaSelector,
  getCurrentViewType,
  insightsActiveSelector,
} from 'src/store/explore/selectors'
import {
  getActiveMetric,
  getBoundsForCollection,
  isMapLoading,
  mapGeo,
} from 'src/store/map/selectors'
import { loadCollectionMap } from 'src/store/map/actions'
import { Box, Button, CircularProgress } from '@material-ui/core'
import { GeoArea, Metric } from 'src/store/map/model'
import { heatmapRankColors } from 'src/components/Views/Explore/LSMap/colors'
import { resolvePath, trackEvent } from 'src/utils'
import {
  setActiveChildFromGeo,
  setCollectionActive,
} from 'src/store/explore/actions'
import MapStyleDialog from 'src/components/Views/Explore/LSMap/MapStyleDialog'

import DefaultMapStyle from 'src/components/Views/Explore/LSMap/defaultMapStyle.json'
import { youSelector } from 'src/store/mortgagees/selectors'
import AreaStatsPopover from 'src/components/dataDisplay/AreaStatsPopover'
import { ExploreTabs } from 'src/models/routes'
import { List, Zap } from 'react-feather'
import { ViewType } from 'src/store/explore/model'
import { getStatisticalAreaTypeFromGeo } from 'src/store/statisticalAreas/utils'
import { getGeoAreaTypeFromGeo } from 'src/store/geographicalAreas/util'

interface MapBlockProps {
  visible: boolean
}

export enum MapType {
  Other = 'Other',
  Suburb = 'Suburb',
  Postcode = 'Postcode',
  Meshblock = 'Meshblock',
}

const getSaLevel = (activeLayer: number) => {
  switch (activeLayer) {
    case 2:
      return 'sa1'
    case 1:
      return 'sa2'
    case 0:
    default:
      return 'sa3'
  }
}

const MapBlock: React.FC<MapBlockProps> = ({ visible }) => {
  const dispatch = useDispatch()
  const activeArea = useSelector(activeAreaSelector)
  const layerData = useSelector(mapGeo)
  const mapLoading = useSelector(isMapLoading)
  const activeMetric = useSelector(getActiveMetric)
  const isInsightsActive = useSelector(insightsActiveSelector)

  const hierarchyType = useSelector(getCurrentViewType)

  const activeBounds = useSelector(getBoundsForCollection)

  let metricPath: string
  switch (activeMetric) {
    case Metric.LotShare:
      metricPath = 'ranks.lotShare'
      break
    case Metric.MortgageShare:
      metricPath = 'ranks.mortgageShare'
      break
  }

  useEffect(() => {
    if (activeArea != null) {
      dispatch(loadCollectionMap(activeArea?.collectionId))
    }
  }, [activeArea?.collectionId, hierarchyType])

  const [deckGl, setDeckGl] = useState<GoogleMapsOverlay | null>(null)
  const googleMap = useRef<any>()

  const [activeLayer, setActiveLayer] = useState<number | null>(0)

  const [selectedArea, setSelectedArea] = useState<PickInfo<
    Feature<Polygon, GeoArea>
  > | null>(null)

  let activeType = null
  if (selectedArea) {
    switch (hierarchyType) {
      case ViewType.Statistical:
        activeType = getStatisticalAreaTypeFromGeo(
          selectedArea.object.properties.parents
        )
        break
      case ViewType.Geographical:
        activeType = getGeoAreaTypeFromGeo(
          selectedArea.object.properties.parents
        )
        break
    }
  }

  useEffect(() => {
    if (activeBounds == null) return

    const bounds = new window.google.maps.LatLngBounds()
    bounds.extend(activeBounds.sw)
    bounds.extend(activeBounds.ne)

    googleMap.current?.fitBounds(bounds)
  }, [activeBounds])

  const us = useSelector(youSelector)
  const ourStats = selectedArea?.object.properties.stats.find(
    mortgagee => mortgagee.id === us?.id
  )

  useEffect(() => {
    if (selectedArea == null || activeArea == null) return

    const currentId = selectedArea.object.properties.id

    if (activeArea.activeChildId !== currentId) {
      setSelectedArea(null)
    }
  }, [activeArea])

  useEffect(() => {
    setSelectedArea(null)

    if (activeLayer) {
      trackEvent('mhs_web_heatmap_breakdown', {
        layer: activeMetric,
        level: getSaLevel(activeLayer),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLayer])

  useEffect(() => {
    setSelectedArea(null)
  }, [hierarchyType])

  const layerId = (id: number) => `layer-${id}`

  const colorForBucket = (bucket: number) =>
    heatmapRankColors[
      Math.min(Math.max(bucket, 0), heatmapRankColors.length - 1)
    ]

  const createLayer = (
    id: number,
    data: FeatureCollection,
    invertedLine: boolean
  ) =>
    new GeoJsonLayer<Feature<Polygon>>({
      id: layerId(id),
      data,
      pickable: true,
      visible: activeLayer != null && layerId(activeLayer) === layerId(id),
      stroked: true,
      filled: true,
      extruded: false,
      lineWidthScale: 8,
      lineWidthMinPixels: 1,
      getFillColor: (feature: Feature<Polygon>) =>
        feature && selectedArea && feature.id === selectedArea.object.id
          ? [55, 105, 133, 179]
          : [
              ...colorForBucket(resolvePath(metricPath, feature.properties)),
              178,
            ],
      getLineColor: (feature: Feature<Polygon>) =>
        feature && selectedArea && feature.id === selectedArea.object.id
          ? [55, 105, 133, 255]
          : [...heatmapRankColors[heatmapRankColors.length - 1], 255],
      getLineWidth: 0.5,
      getOffset: 0.5 * (invertedLine ? 1.0 : -1.0),
      getRadius: 100,
      getElevation: 30,
      updateTriggers: {
        getFillColor: [selectedArea?.index, activeMetric],
        getLineColor: [selectedArea?.index, activeMetric],
      },
      onClick: (clicked: PickInfo<Feature<Polygon, GeoArea>>) => {
        dispatch(setActiveChildFromGeo(clicked.object.properties))
        setSelectedArea(clicked)

        if (activeLayer) {
          trackEvent('mhs_web_heatmap_selection', {
            layer: activeMetric,
            level: getSaLevel(activeLayer),
          })
        }
      },
      extensions: [new PathStyleExtension({ offset: true })],
    })

  const layers =
    layerData?.map((layer, index) => createLayer(index, layer, true)) ?? []

  deckGl?.setProps({ layers })

  const handleApiLoaded = (map: any, _maps: any) => {
    const deck = new GoogleMapsOverlay({ layers })
    deck.setMap(map)
    setDeckGl(deck)
    googleMap.current = map
  }

  const [styleOpen, setStyleOpen] = useState(false)
  const [mapStyles, setMapStyles] = useState<MapTypeStyle[]>(DefaultMapStyle)

  const maxZoom = 15
  const minZoom = 8
  const zoomRange = maxZoom - minZoom
  const zoomItter = Math.ceil(zoomRange / layers.length)

  const popoverNavButtons = [
    {
      tab: ExploreTabs.Data,
      display: (
        <>
          <List />
          View List
        </>
      ),
    },
  ]

  if (isInsightsActive) {
    popoverNavButtons.push({
      tab: ExploreTabs.Insights,
      display: (
        <>
          <Zap />
          View Insights
        </>
      ),
    })
  }

  return (
    <div className={`map ${visible ? 'showme' : 'hideme'}`} id="mapid">
      {mapLoading && (
        <div className="map-loading">
          <div className="loading-dialog">
            <CircularProgress thickness={6} />
            <p>Loading...</p>
          </div>
        </div>
      )}

      <MapLegend />
      {process.env.REACT_APP_MAPS_STYLE_INPUT === 'true' && (
        <>
          <Box position="absolute" bottom={5} left={10} zIndex={100}>
            <Button onClick={() => setStyleOpen(true)}>Set Style</Button>
          </Box>

          <MapStyleDialog
            open={styleOpen}
            handleClose={() => setStyleOpen(false)}
            updateStyle={(styles: MapTypeStyle[]) => setMapStyles(styles)}
          />
        </>
      )}

      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY as string }}
        defaultCenter={{
          lat: -25.426,
          lng: 119.3,
        }}
        defaultZoom={6}
        options={{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles: mapStyles,
        }}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onZoomAnimationEnd={zoomLevel => {
          if (zoomLevel < minZoom) {
            setActiveLayer(0)
          } else if (zoomLevel > maxZoom) {
            setActiveLayer(layers.length - 1)
          } else {
            setActiveLayer(
              layers.findIndex(
                (_layer, index) =>
                  zoomLevel <= zoomItter * (index + 1) + minZoom
              )
            )
          }
        }}
      >
        {selectedArea && selectedArea.coordinate && (
          <AreaStatsPopover
            lat={selectedArea.coordinate[1]}
            lng={selectedArea.coordinate[0]}
            activeType={activeType}
            stats={ourStats?.stats}
            totals={selectedArea.object.properties.total}
            location={selectedArea.object.properties.name}
            onDismiss={event => {
              event.stopPropagation()

              if (activeArea?.collectionId == null) return
              dispatch(setCollectionActive(activeArea.collectionId))
              setSelectedArea(null)
            }}
            navTabs={popoverNavButtons}
          />
        )}
      </GoogleMapReact>
    </div>
  )
}

export default MapBlock
