import {
  GeographicalArea,
  GeographicalAreaType,
  GeographicalGeoParents,
  GeographicalParents,
} from 'src/store/geographicalAreas/model'
import { StatisticalParents } from 'src/store/statisticalAreas/model'

export const getGeographicalAreaTypeFromParents = (
  parents: GeographicalParents
): GeographicalAreaType => {
  const { collectionId, postcodeId } = parents
  if (collectionId != null && postcodeId != null) {
    return GeographicalAreaType.Suburb
  } else if (collectionId != null) {
    return GeographicalAreaType.Postcode
  } else {
    return GeographicalAreaType.Collection
  }
}

export const getGeographicalAreaType = (
  area: GeographicalArea
): GeographicalAreaType => getGeographicalAreaTypeFromParents(area.parents)

export const getGeoAreaTypeFromGeo = (
  parents: GeographicalGeoParents
): GeographicalAreaType => {
  const { postcode } = parents
  if (postcode != null) {
    return GeographicalAreaType.Suburb
  } else {
    return GeographicalAreaType.Postcode
  }
}

export const fromGeoGeoParents = (parents: GeographicalGeoParents) =>
  <GeographicalParents>{
    collectionId: parents.sa3,
    postcodeId: 'postcode' in parents ? parents.postcode : null,
  }
