import { RootState } from 'src/store/rootReducer'
import { Mortgagee } from 'src/store/mortgagees/model'

export const allMortgageesSelector = (state: RootState): Mortgagee[] =>
  state.mortgagees.mortgagees

export const youSelector = (state: RootState): Mortgagee | null =>
  state.mortgagees.you

export const otherMortgageesSelector = (state: RootState): Mortgagee[] =>
  state.mortgagees.mortgagees.filter(
    mortgagee => mortgagee.id !== state.mortgagees.you?.id
  )
