import React from 'react'
import styled from 'styled-components'

import { Button, ButtonProps, CircularProgress } from '@material-ui/core'

const Spinner = styled(CircularProgress)`
  color: #fff;
`
interface Props extends ButtonProps {
  loading: boolean
}

const LoadingButton: React.FC<Props> = ({
  loading,
  children,
  disabled,
  ...props
}) => (
  <Button {...props} disabled={loading || disabled}>
    {loading ? <Spinner size={20} /> : children}
  </Button>
)

export default React.memo(LoadingButton)
