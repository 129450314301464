import { useForm } from 'react-hook-form'
import { PlayCircle, XCircle } from 'react-feather'
import { yupResolver } from '@hookform/resolvers/yup'

import { Box, Typography } from '@material-ui/core'

import {
  Form,
  DummyForm,
  Container,
  SubmitButton,
  IllustrationContainer,
  IllustrationCornerContainer,
  ErrorContainer,
  PasswordRequirements,
} from 'src/modules/auth/common'
import FormTextField from 'src/components/inputs/FormTextField'

import plantsLeft from 'src/Images/plants-bottom-left.svg'
import plantsRight from 'src/Images/plants-bottom-right.svg'
import { ReactComponent as InsightfulLogo } from 'src/Images/insightful-logo.svg'
import useAuth from 'src/hooks/useAuth'
import { object, string, ref, number } from 'yup'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

const resetPasswordSchema = object().shape({
  code: string()
    .length(6, 'Your code must be 6 digits')
    .matches(/^[0-9]*$/, 'The code must be a number'),
  newPassword: PasswordRequirements.required('Please enter a new password'),
  confirmNewPassword: string()
    .required('Please re-enter your new password')
    .oneOf([ref('newPassword')], 'Passwords must match'),
})

export interface ResetLocationState {
  username: string
}

const ResetPassword: React.FC = () => {
  const [hasSentCode, setHasSentCode] = useState(false)
  const [emailPending, setEmailPending] = useState(false)

  const [submitError, setSubmitError] = useState('')
  const { resetPassword, confirmPasswordReset } = useAuth()

  const { state } = useLocation<ResetLocationState>()

  const { control, errors, formState, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: { code: '', newPassword: '', confirmNewPassword: '' },
  })
  const { isSubmitting } = formState

  const onSubmitReset = handleSubmit(async ({ code, newPassword }) => {
    try {
      await confirmPasswordReset(state.username, code, newPassword)
    } catch (error) {
      if (error.type === 'ExpiredCodeException') {
        setHasSentCode(false)
        reset()
      }
      setSubmitError(error.message)
    }
  })

  const onSendEmail = async () => {
    try {
      setEmailPending(true)
      await resetPassword(state.username)
      setHasSentCode(true)
    } catch (error) {
      setSubmitError(error.message)
    } finally {
      setEmailPending(false)
    }
  }

  return (
    <Container>
      <InsightfulLogo />
      {!!submitError && (
        <ErrorContainer>
          <XCircle />
          <Typography variant="subtitle1">{submitError}</Typography>
        </ErrorContainer>
      )}
      {hasSentCode ? (
        <Form onSubmit={onSubmitReset}>
          <Box mb={2}>
            <Typography variant="h4" align="center">
              Password Reset
            </Typography>
            <Typography variant="subtitle1" align="center">
              Please enter the code that was sent to your email address along
              with your new password.
            </Typography>
          </Box>
          <FormTextField
            fullWidth
            errors={errors}
            type="text"
            control={control}
            id="code"
            name="code"
            label="Verification code"
            placeholder="Enter your verification code"
          />
          <FormTextField
            fullWidth
            errors={errors}
            type="password"
            control={control}
            id="new-password"
            name="newPassword"
            label="New password"
            placeholder="Enter password"
          />
          <FormTextField
            fullWidth
            type="password"
            name="confirmNewPassword"
            errors={errors}
            label="Confirm Password"
            control={control}
            id="confirm-new-password"
            placeholder="Re-enter password"
          />
          <SubmitButton
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            loading={isSubmitting}
          >
            <PlayCircle />
            Save
          </SubmitButton>
        </Form>
      ) : (
        <DummyForm>
          <Box mb={2}>
            <Typography variant="h4" align="center">
              Password Reset
            </Typography>
            <Typography variant="subtitle1" align="center">
              We need to send a one time code to your email to continue the
              reset process
            </Typography>
          </Box>
          <SubmitButton
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            loading={emailPending}
            onClick={onSendEmail}
          >
            Send code
          </SubmitButton>
        </DummyForm>
      )}
      <IllustrationContainer>
        <IllustrationCornerContainer>
          <img src={plantsLeft} alt="PlantsLeft" />
        </IllustrationCornerContainer>
        <div />
        <IllustrationCornerContainer>
          <img src={plantsRight} alt="PlantsRight" />
        </IllustrationCornerContainer>
      </IllustrationContainer>
    </Container>
  )
}

export default ResetPassword
