import { useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'

import { trackEvent } from 'src/utils'
import { Metric } from 'src/store/map/model'
import { setActiveMetric } from 'src/store/map/actions'
import { getActiveMetric } from 'src/store/map/selectors'

export const MetricSelector: React.FC = () => {
  const dispatch = useDispatch()
  const activeMetric = useSelector(getActiveMetric)

  return (
    <>
      <Select
        value={activeMetric}
        variant="outlined"
        className="heat-select"
        onChange={event => {
          const selectedMetric = event.target.value as Metric

          dispatch(setActiveMetric(selectedMetric))
          trackEvent('mhs_web_heatmap_layer', { layer: selectedMetric })
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          getContentAnchorEl: null,
        }}
      >
        {Object.values(Metric).map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default MetricSelector
