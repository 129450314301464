import { roundTo } from 'src/utils/index'

export type ValueFormatter = (value: number) => string

export const numericFormatter: ValueFormatter = (value: number) =>
  roundTo(value, 2).toString()
export const percentFormatter: ValueFormatter = (value: number) =>
  `${roundTo(value, 2)}%`
export const currencyFormatter: ValueFormatter = (value: number) => {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formatter.format(roundTo(value, 2))
}

export const getFormatter = (format: string): ValueFormatter => {
  switch (format) {
    case 'percent':
      return percentFormatter
    case 'currency':
      return currencyFormatter
    default:
      return numericFormatter
  }
}
