import { Link } from 'react-router-dom'
// import { Link, NavLink } from 'react-router-dom'
// import { Bell, Settings, HelpCircle } from 'react-feather'

import { ExploreTabs, Routes } from 'src/models/routes'

import ProfileMenu from '../navigation/ProfileMenu'

import Logo from 'src/Images/logo.svg'

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <div className="nav-top">
        <div className="pages">
          <Link className="logo" to={Routes.Explore + ExploreTabs.Map}>
            <img src={Logo} alt="Logo" />
          </Link>
          {/* TODO: Enable these as functionality is added */}
          {/* <NavLink exact activeClassName="selected" to={Routes.Summary}>
           Summary
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={Routes.Explore + ExploreTabs.Map}
          >
            Explore
          </NavLink>
          <NavLink activeClassName="selected" to={Routes.Reports}>
            Reports
          </NavLink>
          <NavLink activeClassName="selected" to={Routes.Collections}>
            My Collections
          </NavLink> */}
        </div>
        <div className="settings">
          {/* TODO: Add these back as the functionality is added */}
          {/* <span>
            <Settings />
          </span>
          <span>
            <HelpCircle />
          </span>
          <span>
            <Bell />
          </span> */}
          <ProfileMenu />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
