import {
  StatisticalArea,
  StatisticalAreaType,
  StatisticalGeoParents,
  StatisticalParents,
} from 'src/store/statisticalAreas/model'

export const getStatisticalAreaTypeFromParents = (
  parents: StatisticalParents
): StatisticalAreaType => {
  const { collectionId, sa1Id, sa2Id } = parents
  if (collectionId != null && sa2Id != null && sa1Id != null) {
    return StatisticalAreaType.MeshBlock
  } else if (collectionId != null && sa2Id != null) {
    return StatisticalAreaType.SA1
  } else if (collectionId != null) {
    return StatisticalAreaType.SA2
  } else {
    return StatisticalAreaType.Collection
  }
}

export const getStatisticalAreaType = (
  area: StatisticalArea
): StatisticalAreaType => getStatisticalAreaTypeFromParents(area.parents)

export const getStatisticalAreaTypeFromGeo = (
  parents: StatisticalGeoParents
): StatisticalAreaType => {
  const { sa1, sa2 } = parents
  if (sa2 != null && sa1 != null) {
    return StatisticalAreaType.MeshBlock
  } else if (sa2 != null) {
    return StatisticalAreaType.SA1
  } else {
    return StatisticalAreaType.SA2
  }
}

export const fromStatsGeoParents = (geoParents: StatisticalGeoParents) =>
  <StatisticalParents>{
    collectionId: geoParents.sa3,
    sa1Id: 'sa1' in geoParents ? geoParents.sa1 : null,
    sa2Id: 'sa2' in geoParents ? geoParents.sa2 : null,
  }
