import {
  ActionReducerMapBuilder,
  AsyncThunk,
  CaseReducer,
} from '@reduxjs/toolkit'

export const endpointUrl = process.env.REACT_APP_ENDPOINT

export interface ApiState {
  isLoading: boolean
  hasError: boolean
}

export const apiStateTemplate: ApiState = {
  isLoading: false,
  hasError: false,
}

export const generateApiReducers = (
  builder: ActionReducerMapBuilder<ApiState>
) => (
  thunk: AsyncThunk<any, any, any>,
  dataReducer: CaseReducer | null
): void => {
  builder.addCase(thunk.fulfilled, (state, action) => {
    if (dataReducer) dataReducer(state, action)
    state.isLoading = false
  })
  builder.addCase(thunk.pending, state => {
    state.isLoading = true
    state.hasError = false
  })
  builder.addCase(thunk.rejected, state => {
    state.isLoading = false
    state.hasError = true
  })
}
