import { BaseArea } from 'src/store/sharedModels'

export interface GeographicalParents {
  [key: string]: string | null

  collectionId: string | null
  postcodeId: string | null
}

export interface GeographicalArea extends BaseArea {
  parents: GeographicalParents
}

export enum GeographicalAreaType {
  Collection = 'Collection',
  Postcode = 'Postcode',
  Suburb = 'Suburb',
}

export interface GeographicalGeoParents {
  sa3: string
  postcode?: string
}
