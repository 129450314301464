import { Auth } from 'aws-amplify'

export const authFetch = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => {
  const session = await Auth.currentSession()

  const authInit = init ?? {}
  authInit.headers = {
    ...authInit.headers,
    authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
  }

  return await fetch(input, authInit)
}

export default authFetch
