import { createMuiTheme } from '@material-ui/core/styles'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'

const fontFamily = '"Nunito Sans", Roboto, Helvetica, Arial, sans-serif'

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 600
const fontWeightBold = 700

export const palette: PaletteOptions = {
  primary: {
    main: '#4184EF',
    light: '#3867a3',
    xlight: '#6897d3',
    dark: '#1755B8',
    contrastText: '#fff',
  },
  secondary: {
    main: '#EFAC41',
    light: '#FDF7EC',
    dark: '#D5952E',
    contrastText: '#4B87FF',
  },
  background: {
    light: '#F5F7FA',
    grey: '#E4E4E4',
    default: '#fff',
    dark: '#302e34',
    paper: '#fff',
  },
  disabled: {
    main: '#949494',
    light: '#f4f4f4',
  },
  success: {
    main: '#4CAF50',
    light: '#CFFFD2',
    xlight: '#F5FDF7',
  },
  warning: {
    main: '#FF9800',
    xlight: '#fffaea',
  },
  error: {
    main: '#E03131',
    light: '#FFCDCD',
    xlight: '#fcedf4',
  },
  text: {
    primary: '#000000',
    secondary: '#949494',
    contrast: '#FFFFFF',
  },
  divider: '#ECECEC',
  tooltip: {
    main: '#0b1f31',
    secondary: '#FFFFFF',
  },
  toast: {
    info: '#4B87FF',
    error: '#D2003C',
    success: '#84D584',
  },
  greys: {
    pale: '#F5F5F5',
    light: '#C4C4C4',
    normal: '#A0A0A0',
    medium: '#6C6C6C',
    mediumDark: '#464646',
    dark: '#bdbdbd',
    black: '#111111',
  },
  type: 'light',
  chartColors: ['#EFAC41', '#296686', '#3A7FA0', '#5799BB', '#ADB5BD'],
}

const theme = createMuiTheme({
  palette,
  props: {
    MuiWithWidth: {
      initialWidth: 'xs',
    },
    MuiButton: {
      color: 'primary',
      variant: 'contained',
      disableElevation: true,
    },
  },
})

theme.shadows[1] = '0px 25px 35px -10px rgba(102, 73, 28, 0.07)'

// We can only use typography function such as pxToRem after the createMuiTheme
theme.typography = {
  ...theme.typography,
  fontFamily,
  fontWeightBold,
  fontWeightLight,
  fontWeightRegular,
  fontFamilySecondary: fontFamily,
  h1: {
    ...theme.typography.h1,
    fontFamily: fontFamily,
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(56),
    lineHeight: theme.typography.pxToRem(70),
  },
  h2: {
    ...theme.typography.h2,
    fontFamily: fontFamily,
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(46),
    lineHeight: theme.typography.pxToRem(46),
  },
  h3: {
    ...theme.typography.h3,
    fontFamily: fontFamily,
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
  },
  h4: {
    ...theme.typography.h4,
    fontFamily: fontFamily,
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(22),
    lineHeight: theme.typography.pxToRem(32),
  },
  h5: {
    ...theme.typography.h5,
    fontFamily: fontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  },
  h6: {
    ...theme.typography.h6,
    fontFamily: fontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
  },
  subtitle1: {
    ...theme.typography.subtitle1,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
  },
  subtitle2: {
    ...theme.typography.subtitle2,
  },
  body1: {
    ...theme.typography.body1,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  body2: {
    ...theme.typography.body2,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(32),
  },
  button: {
    ...theme.typography.button,
    fontFamily: fontFamily,
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  caption: {
    ...theme.typography.caption,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
  },
  overline: {
    ...theme.typography.overline,
  },
}

// Overrides are assigned here so we can use theme.breakpoints
// Defaults can be deleted – these are used to test that Storybook Mui theming works
theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: 8,
      textTransform: 'none',
      padding: theme.spacing(1.5),
    },
    contained: {
      backgroundColor: '#fff',
    },
    outlined: {
      border: `1px solid ${theme.palette.greys.normal}`,
    },
    containedSizeSmall: {
      padding: theme.spacing(1, 1.5),
    },
  },
  MuiTableRow: {
    root: {},
  },
  MuiTableCell: {
    stickyHeader: {
      color: theme.palette.greys.black,
      fontWeight: 'bold',
      borderBottom: '1px solid #eaeaea',
    },
    body: {
      color: theme.palette.greys.medium,
      borderBottom: '1px solid #eaeaea',
    },
  },
  MuiPopover: {
    root: {
      zIndex: 1001,
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 20,
    },
  },
  MuiDivider: {
    root: { height: 2, backgroundColor: theme.palette.greys.pale },
  },
  MuiAvatar: {
    root: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
    },
  },
  MuiOutlinedInput: {
    root: { backgroundColor: theme.palette.greys.pale, borderRadius: 8 },
    input: {
      padding: theme.spacing(1.75, 1.5),
    },
  },
  MuiFormControl: {
    root: {
      width: '100%',
    },
  },
  MuiFormHelperText: {
    root: {
      padding: theme.spacing(0, 0, 0, 1.5),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  MuiFormLabel: {
    root: {
      ...theme.typography.subtitle1,
      color: theme.palette.greys.black,
    },
  },
  MuiTooltip: {
    tooltip: {
      color: '#000',
      borderRadius: 20,
      backgroundColor: '#fff',
      padding: theme.spacing(2),
      boxShadow: theme.shadows[1],
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(24),
    },
    arrow: {
      color: '#fff',
    },

    tooltipPlacementBottom: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: -10,
      },
    },
  },
  MuiTableSortLabel: {
    icon: {
      marginLeft: 8,
      fill: theme.palette.greys.black,
    },
  },
  MuiIconButton: {
    root: {
      padding: theme.spacing(1),
    },
    label: {
      width: '75%',
    },
  },
}

export default theme
