import { Circle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  activeAreaNamedSelector,
  activeAreaSelector,
  activeAreaStatsSelector,
  activeAreaTypeSelector,
  activeCollectionSelector,
} from 'src/store/explore/selectors'
import { getActiveMetric } from 'src/store/map/selectors'
import { mapColors } from 'src/utils/mortgageeColor'
import TrendPill from 'src/components/Util/TrendPill'
import { Cell, Pie, PieChart, Tooltip, TooltipProps } from 'recharts'
import { AreaStats } from 'src/store/sharedModels'
import { Metric } from 'src/store/map/model'
import {
  allMortgageesSelector,
  youSelector,
} from 'src/store/mortgagees/selectors'
import { Breadcrumbs, Link, Paper } from '@material-ui/core'
import { setActiveChild, setCollectionActive } from 'src/store/explore/actions'
import breadcrumbSeparator from 'src/Images/breadcrumb-separator.svg'
import { AreaType } from 'src/store/explore/model'
import { formatPercentage } from 'src/utils'
import { useState } from 'react'
import { percentFormatter, ValueFormatter } from 'src/utils/valueFormatters'

const selectStat = (stats: AreaStats | undefined, metric: Metric) => {
  switch (metric) {
    case Metric.LotShare:
      return stats?.lot
    case Metric.MortgageShare:
      return stats?.mortgage
  }
}

interface PieTooltipProps extends TooltipProps {
  colorMappings: any
  valueFormatter: ValueFormatter
  youId?: string
}

const PieTooltip: React.FC<PieTooltipProps> = ({
  active,
  payload,
  colorMappings,
  valueFormatter,
  youId,
}) => {
  if (active && payload && payload.length) {
    return (
      <Paper elevation={2} style={{ padding: 15 }}>
        {payload.map((item: any) => {
          const { id, name } = item.payload.mortgagee

          return (
            <span key={id} className="color-bubble">
              <Circle
                style={{
                  stroke: colorMappings[id],
                  fill: colorMappings[id],
                }}
              />
              {id === youId ? 'You' : name} :{' '}
              {typeof item.value == 'number'
                ? valueFormatter(item.value)
                : item.value}
            </span>
          )
        })}
      </Paper>
    )
  }

  return null
}

const FloatingPanel: React.FC = () => {
  const dispatch = useDispatch()

  const activeCollection = useSelector(activeCollectionSelector)
  const activeArea = useSelector(activeAreaSelector)
  const activeAreaType = useSelector(activeAreaTypeSelector)
  const activeAreaData = useSelector(activeAreaStatsSelector)
  const activeMetric = useSelector(getActiveMetric)

  const mortgagees = useSelector(allMortgageesSelector)
  const youId = useSelector(youSelector)?.id

  const activeMortgageesData = activeAreaData?.mortgageeStats
    ?.map(statMortgagee => {
      const stat = selectStat(statMortgagee?.stats, activeMetric)

      return {
        mortgagee:
          mortgagees.find(mortgagee => mortgagee.id === statMortgagee.id) ??
          statMortgagee,
        value: (stat?.share ?? 0) * 100,
      }
    })
    .slice()
    .sort((ma, mb) => {
      if (ma.mortgagee.id === youId) return -1
      if (mb.mortgagee.id === youId) return 1
      return ma.value > mb.value ? -1 : 1
    })

  const mappedColorData = mapColors(activeMortgageesData ?? [])

  const ourStats = selectStat(activeAreaData?.stats, activeMetric)

  const namedAreas = useSelector(activeAreaNamedSelector)

  const [activeId, setActiveId] = useState('')

  const onBreadcrumbClick = (id: string) => () => {
    if (activeArea == null) return
    dispatch(setActiveChild(id))
  }

  const onCollectionClick = () => {
    if (activeCollection?.id == null) return
    dispatch(setCollectionActive(activeCollection.id))
  }

  return (
    <div className="floating-panel">
      <div className="floating-panel-head">
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<img src={breadcrumbSeparator} alt="separator" />}
        >
          {activeCollection && (
            <Link color="textPrimary" onClick={onCollectionClick}>
              {activeCollection.name}
            </Link>
          )}
          {namedAreas.map(area => (
            <Link
              key={area.id}
              color="textPrimary"
              onClick={onBreadcrumbClick(area.id)}
            >
              {area.name}
            </Link>
          ))}
        </Breadcrumbs>
      </div>

      <div className="floating-panel-body">
        <div className="tab-overview">
          <div className="panel-chart">
            <span className="tag">{activeAreaType}</span>
            <strong>{activeAreaData?.name}</strong>
            <p>
              You have an aggregate
              <span> {formatPercentage(ourStats?.share ?? 0)} </span>
              {activeMetric} within
              <span> {activeAreaData?.name}</span>.
            </p>

            <div className="chart-container">
              <div className="share">
                <p>{formatPercentage(ourStats?.share ?? 0)}</p>
                <TrendPill figure={ourStats?.trend} />
              </div>

              <PieChart
                width={250}
                height={250}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <Pie
                  dataKey="value"
                  key={activeMetric}
                  data={activeMortgageesData}
                  cx={125}
                  cy={125}
                  innerRadius={100}
                  outerRadius={125}
                  onMouseEnter={data => setActiveId(data.payload.mortgagee.id)}
                  onMouseLeave={() => setActiveId('')}
                >
                  {activeMortgageesData?.map(mortgagee => (
                    <Cell
                      key={mortgagee.mortgagee.id}
                      fill={mappedColorData[mortgagee.mortgagee.id]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  cursor={false}
                  content={
                    <PieTooltip
                      colorMappings={mappedColorData}
                      valueFormatter={percentFormatter}
                      youId={youId}
                    />
                  }
                />
              </PieChart>
            </div>
          </div>
        </div>

        <div className="panel-inputs">
          <div className="inp">
            <div className="lbl">{activeMetric}</div>
            <div className="panel-legend">
              {activeMortgageesData?.map(mortgagee => {
                const { id, name } = mortgagee.mortgagee
                return (
                  <span key={id} className={id === activeId ? 'active' : ''}>
                    <Circle
                      style={{
                        stroke: mappedColorData[id],
                        fill: mappedColorData[id],
                      }}
                    />
                    {id === youId ? 'You' : name}
                  </span>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FloatingPanel
