import { TrendingUp, TrendingDown } from 'react-feather'

interface TrendPillProps {
  figure: any // TODO correct typing
  pillType?: any // TODO correct typing
}

export const TrendPill: React.FC<TrendPillProps> = ({
  figure,
  pillType = '',
}) => {
  return (
    <div className="trend-pill">
      <span className="arrow">
        {figure > 0 ? <TrendingUp /> : <TrendingDown />}
      </span>

      {pillType === '' ? (
        <span className="number">{figure}%</span>
      ) : (
        <span className="number" />
      )}
    </div>
  )
}

export default TrendPill
