import { OverviewInsight } from 'src/store/insights/model'
import StatBox from 'src/components/Util/StatBox'

interface StatProps {
  statData: OverviewInsight
}

const TopStats: React.FC<StatProps> = ({ statData }) => {
  const { count, share } = statData

  return (
    <div className="insight-stats">
      <StatBox heading="Count" stat={count} />
      <StatBox heading="Share" stat={`${share}%`} />
    </div>
  )
}

export default TopStats
