import { AreaType } from 'src/store/explore/model'

export const resolvePath = (
  path: string | string[],
  obj: any,
  separator = '.'
) => {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

// https://stackoverflow.com/a/18358056/950853
export const roundTo = (num: number, decimals: number): number =>
  +(Math.round(Number(num + `e+${decimals}`)) + `e-${decimals}`)

export const formatPercentage = (num: number): string =>
  `${roundTo(num * 100, 2)}%`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trackEvent = (name: string, data: any): void => {
  window.gtag('event', name, data)
}
