import { useState } from 'react'
import styled, { css } from 'styled-components'

import {
  Fade,
  Button,
  Popper,
  Divider,
  Typography,
  ClickAwayListener,
  Paper as MuiPaper,
  Avatar as MuiAvatar,
} from '@material-ui/core'

import useAuth from 'src/hooks/useAuth'

const MENU_ID = 'profile-menu'

const Avatar = styled(MuiAvatar)(
  ({ theme }) => css`
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-left: ${theme.spacing(3)}px;
    background-color: ${theme.palette.secondary.main};
  `
)

const Popover = styled(Popper)`
  height: auto;
  z-index: 1001;
  margin: ${({ theme }) => theme.spacing(1.5, 0.5, 0, 0)};
`

const Paper = styled(MuiPaper)`
  position: relative;
`

const PopoverPointer = styled.div`
  top: -4px;
  right: 16px;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  position: absolute;
  background-color: #fff;
  transform: rotate(45deg);
`

const Content = styled.div(
  ({ theme }) => css`
    min-width: 280px;
    padding: ${theme.spacing(2, 2.5)};
  `
)

const Name = styled(Typography)(
  ({ theme }) => css`
    font-weight: bold;
    color: ${theme.palette.greys.normal};
  `
)

const Actions = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(1, 2)};
  `
)

const ProfileMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { user, logout } = useAuth()

  const givenName = (user?.attributes?.given_name as string) ?? ' '
  const familyName = (user?.attributes?.family_name as string) ?? ' '

  const initials = givenName[0] + familyName[0]

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Avatar
        role="button"
        onClick={openMenu}
        aria-haspopup="true"
        aria-controls={MENU_ID}
      >
        {initials}
      </Avatar>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClose}
      >
        <Popover
          transition
          id={MENU_ID}
          placement="bottom"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper>
                <PopoverPointer />
                <Content>
                  <Name variant="body2">
                    {givenName} {familyName}
                  </Name>
                  <Typography variant="body1">
                    {user?.attributes.email}
                  </Typography>
                </Content>
                <Divider />
                <Actions>
                  <Button color="default" onClick={logout}>
                    Log out
                  </Button>
                </Actions>
              </Paper>
            </Fade>
          )}
        </Popover>
      </ClickAwayListener>
    </>
  )
}

export default ProfileMenu
