import { LineData } from 'src/store/insights/model'
import { ChevronDown, Circle } from 'react-feather'
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import { Checkbox, SvgIcon } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { otherMortgageesSelector } from 'src/store/mortgagees/selectors'
import { trackEvent } from 'src/utils'

export interface ValueSelectorProps {
  lines: LineData[]
  activeIds: string[]
  colorMap: any
  setActiveIds: Dispatch<SetStateAction<string[]>>
}

export const ValueSelector: React.FC<ValueSelectorProps> = ({
  lines,
  activeIds,
  colorMap,
  setActiveIds,
}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const toggleLine = (lineId: string) => {
    if (activeIds.indexOf(lineId) >= 0) {
      setActiveIds(currentIds => {
        const newIds = currentIds.filter(activeLine => activeLine !== lineId)

        trackEvent('mhs_web_insight_history_filter', {
          competitors_count: newIds.length,
        })

        return newIds
      })
    } else {
      setActiveIds(currentIds => {
        const newIds = [...currentIds, lineId]

        trackEvent('mhs_web_insight_history_filter', {
          competitors_count: newIds.length,
        })

        return newIds
      })
    }
  }

  const otherMortgagees = useSelector(otherMortgageesSelector)
  const selectableLines = lines.flatMap(line => {
    const found = otherMortgagees.find(mortgagee => mortgagee.id === line.uuid)
    return found ? [found] : []
  })

  return (
    <div className="graph-legend">
      {selectableLines
        .filter(line => activeIds.includes(line.id))
        .map((line, index) => {
          return (
            <span className="color-bubble" key={index}>
              <Circle
                style={{
                  stroke: colorMap[line.id],
                  fill: colorMap[line.id],
                }}
              />
              {line.name}
            </span>
          )
        })}
      <IconButton
        aria-label="open line selection"
        component="span"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <ChevronDown />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom"
        modifiers={{
          flip: {
            enabled: false,
          },
        }}
        style={{
          zIndex: 1000,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {selectableLines.map(line => (
                    <MenuItem
                      className="line-option"
                      key={line.id}
                      onClick={() => toggleLine(line.id)}
                    >
                      <span className="color-bubble">
                        <Circle
                          style={{
                            stroke: colorMap[line.id],
                            fill: colorMap[line.id],
                          }}
                        />
                      </span>
                      {line.name}
                      <Checkbox
                        icon={<SvgIcon />}
                        checkedIcon={<CheckCircleIcon color="primary" />}
                        checked={activeIds.includes(line.id)}
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default ValueSelector
