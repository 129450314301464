import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import authenticatedFetch from 'src/utils/authenticatedFetch'
import { endpointUrl } from 'src/store/apiHelper'
import { FeatureCollection } from 'geojson'
import { BoundsTable, GeoArea, Metric } from 'src/store/map/model'
import { ViewType } from 'src/store/explore/model'
import { RootState } from 'src/store/rootReducer'

interface TransformedData {
  geo: FeatureCollection<any, GeoArea>
  bounds: BoundsTable
}

export const loadCollectionMap = createAsyncThunk<
  void,
  string,
  { state: RootState }
>('map/loadCollectionMap', async (collectionId: string, { getState }) => {
  let geoResponses

  switch (getState().explore.currentViewType) {
    case ViewType.Statistical:
      geoResponses = await Promise.all([
        authenticatedFetch(`${endpointUrl}/collection/${collectionId}/geo/sa2`),
        authenticatedFetch(`${endpointUrl}/collection/${collectionId}/geo/sa1`),
        authenticatedFetch(
          `${endpointUrl}/collection/${collectionId}/geo/meshblocks`
        ),
        authenticatedFetch(`${endpointUrl}/collection/${collectionId}/geo/sa3`),
      ])
      break
    case ViewType.Geographical:
      geoResponses = await Promise.all([
        authenticatedFetch(
          `${endpointUrl}/collection/${collectionId}/geo/postcode`
        ),
        authenticatedFetch(
          `${endpointUrl}/collection/${collectionId}/geo/suburb`
        ),
        authenticatedFetch(`${endpointUrl}/collection/${collectionId}/geo/sa3`),
      ])
      break
  }

  const rawGeo = await Promise.all(
    geoResponses.map(response => response.json())
  )

  const sa3 = rawGeo.pop()

  const geoLayers = rawGeo.map(raw =>
    raw.data.statisticalAreas.reduce(
      (acc: TransformedData, area: GeoArea) => {
        const { id, name, parents, stats, ranks, total, bounds, geo } = area
        acc.bounds[id] = bounds

        acc.geo.features.push({
          type: 'Feature',
          geometry: geo,
          id,
          properties: <GeoArea>{
            id,
            name,
            parents,
            stats,
            ranks,
            total,
            bounds,
          },
        })
        return acc
      },
      {
        geo: {
          type: 'FeatureCollection',
          features: [],
        },
        bounds: sa3.data.statisticalAreas.reduce(
          (acc: BoundsTable, area: GeoArea) => {
            acc[area.id] = area.bounds
            return acc
          },
          {}
        ),
      }
    )
  )

  return geoLayers.reduce(
    (acc, layer) => {
      acc.geo.push(layer.geo)
      acc.bounds = { ...acc.bounds, ...layer.bounds }
      return acc
    },
    { geo: [], bounds: {} }
  )
})

export const setActiveMetric = createAction<Metric>('map/setActiveMetric')
