import { RootState } from 'src/store/rootReducer'
import { StatisticalArea } from 'src/store/statisticalAreas/model'
import { geographicalAreaSelectors } from 'src/store/geographicalAreas/selectors'
import { GeographicalArea } from 'src/store/geographicalAreas/model'
import { ViewType } from 'src/store/explore/model'
import { statisticalAreasSelectors } from 'src/store/statisticalAreas/selectors'
import { getCurrentViewType } from 'src/store/explore/selectors'
import { Collection } from 'src/store/collection/model'
import { collectionSelectors } from 'src/store/collection/selectors'

export const hierarchySwitch = <R = void>(
  state: RootState,
  statsHandler: (area: StatisticalArea) => R,
  geographicalHandler: (area: GeographicalArea) => R,
  collectionHandler?: (area: Collection) => R
): R | null => {
  const viewType = getCurrentViewType(state)
  const active = state.explore.activeArea
  if (active == null) return null

  if (active.activeChildId == undefined) {
    if (collectionHandler == undefined) return null
    const collection = collectionSelectors.selectById(
      state,
      active.collectionId
    )
    if (collection == undefined) return null
    return collectionHandler(collection)
  }

  switch (viewType) {
    case ViewType.Statistical: {
      const area = statisticalAreasSelectors.selectById(
        state,
        active.activeChildId
      )
      if (area == undefined) return null
      return statsHandler(area)
      break
    }
    case ViewType.Geographical: {
      const area = geographicalAreaSelectors.selectById(
        state,
        active.activeChildId
      )
      if (area == undefined) return null
      return geographicalHandler(area)
    }
  }
}
