import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts'
import { BarData } from 'src/store/insights/model'
import { useSelector } from 'react-redux'
import { allMortgageesSelector } from 'src/store/mortgagees/selectors'
import { mapColors } from 'src/utils/mortgageeColor'
import { Paper } from '@material-ui/core'
import { Circle } from 'react-feather'
import { getFormatter, ValueFormatter } from 'src/utils/valueFormatters'
import { roundTo } from 'src/utils'

interface BarChartProp {
  data: BarData[]
  format: string
}

interface BarChartTooltip extends TooltipProps {
  colorMappings: any
  valueFormatter: ValueFormatter
}

const BarChartTooltip: React.FC<BarChartTooltip> = ({
  active,
  payload,
  colorMappings,
  valueFormatter,
}) => {
  if (active && payload && payload.length) {
    return (
      <Paper elevation={2} style={{ padding: 15 }}>
        {payload.map(item => (
          <span key={item.payload.id} className="color-bubble">
            <Circle
              style={{
                stroke: colorMappings[item.payload.id],
                fill: colorMappings[item.payload.id],
              }}
            />
            {item.payload.label} :{' '}
            {typeof item.value == 'number'
              ? valueFormatter(item.value)
              : item.value}
          </span>
        ))}
      </Paper>
    )
  }

  return null
}

const LSBarChart: React.FC<BarChartProp> = ({ data, format }) => {
  const mortgagees = useSelector(allMortgageesSelector)

  const nameMap: any = mortgagees.reduce(
    (current, mortgagee) => ({
      ...current,
      [mortgagee.id]: mortgagee.isCustomer ? 'You' : mortgagee.name,
    }),
    {}
  )

  const chartData = data
    .map(bar => ({
      value: roundTo(bar.value * 100, 2),
      id: bar.label,
      label: nameMap[bar.label] ?? '',
    }))
    .sort((barA, barB) => barB.value - barA.value)

  const mappedColorData = mapColors(
    mortgagees.map(mortgagee => {
      const bar = data.find(bar => mortgagee.id === bar.label)
      return { mortgagee, value: bar?.value ?? 0 }
    })
  )

  const barWidth = Math.min(Math.max(180 - chartData.length * 20, 10), 120)

  const valueFormatter = getFormatter(format)

  return (
    <div className="volume-chart">
      <div className="heading">Snapshot</div>
      <div className="chart-block">
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            data={chartData}
            margin={{
              top: 20,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              vertical={false}
              stroke="#E5EBF0"
              strokeDasharray="1.5"
            />
            <XAxis
              axisLine={false}
              tick={{ fontSize: 12, fontWeight: 600 }}
              tickMargin={20}
              tickSize={0}
              dataKey="label"
            />
            <YAxis
              interval="preserveStartEnd"
              tick={{
                textAnchor: 'start',
                position: 'left',
                fontSize: 12,
                fontWeight: 600,
              }}
              tickMargin={38}
              tickSize={0}
              axisLine={false}
              tickFormatter={valueFormatter}
            />
            <Tooltip
              cursor={false}
              content={
                <BarChartTooltip
                  colorMappings={mappedColorData}
                  valueFormatter={valueFormatter}
                />
              }
            />
            <Bar dataKey="value" barSize={barWidth} radius={[4, 4, 0, 0]}>
              {chartData.map((bar, index) => (
                <Cell key={`cell-${index}`} fill={mappedColorData[bar.id]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default LSBarChart
