import { EventHandler, MouseEvent, MouseEventHandler } from 'react'
import { X } from 'react-feather'
import { useHistory } from 'react-router'
import { Box, Button, Link } from '@material-ui/core'
import { AreaType } from 'src/store/explore/model'
import TrendPill from 'src/components/Util/TrendPill'
import { ExploreTabs, Routes } from 'src/models/routes'
import { AreaStats, Totals } from 'src/store/sharedModels'
import { formatPercentage, trackEvent } from 'src/utils'

interface TabButton {
  tab: ExploreTabs
  display: React.ReactNode
}

interface MapPopoverProps {
  lat?: number
  lng?: number

  totals: Totals
  location: string
  activeType: AreaType | null
  stats: AreaStats | undefined

  navTabs?: TabButton[]
  contentOnly?: boolean
  onNavigate?: () => void
  onDismiss?: (event: MouseEvent) => void
}

const AreaStatsPopover: React.FC<MapPopoverProps> = ({
  stats,
  totals,
  location,
  activeType,
  navTabs = [],
  contentOnly = false,
  onNavigate,
  onDismiss,
}) => {
  const history = useHistory()

  const createLinkClick = (route: string) => (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation()
    onNavigate?.()

    if (route.includes(ExploreTabs.Data)) {
      trackEvent('mhs_web_list', {
        reference: 'icon',
        level: activeType,
      })
    } else if (route.includes(ExploreTabs.Insights)) {
      trackEvent('mhs_web_insight', {
        reference: 'icon',
        level: activeType,
      })
    }

    history.push(route)
  }

  return (
    <div className={contentOnly ? '' : 'map-popup'}>
      <div className="popup-inner-content">
        <div className="pop-top">
          {onDismiss && (
            <Link className="dismiss" aria-label="Dismiss" onClick={onDismiss}>
              <X color="#111111" />
            </Link>
          )}
          <div className="type">{activeType}</div>
          <div className="location">{location}</div>
        </div>

        {stats && (
          <div className="pop-bottom">
            <div className="map-stat-box">
              <div className="lbl">Mortgage Share</div>
              <div className="stats">
                <div className="numbers">
                  <span>{formatPercentage(stats.mortgage.share)}</span> (
                  {stats.mortgage.count}/{totals.mortgages})
                </div>
                <TrendPill figure={stats.mortgage.trend} />
              </div>
            </div>

            <div className="map-stat-box">
              <div className="lbl">Lot Share</div>
              <div className="stats">
                <div className="numbers">
                  <span>{formatPercentage(stats.lot.share)}</span>
                  {/* MHS-236: we currently use the mortgage count for lot counts*/}{' '}
                  ({stats.mortgage.count}/{totals.lots})
                </div>
                <TrendPill figure={stats.lot.trend} />
              </div>
            </div>
          </div>
        )}
        <Box mx={3} display="flex" flexDirection="column" mt={stats ? 0 : 3}>
          {navTabs.map(navTab => (
            <Button
              key={navTab.tab}
              role="link"
              size="small"
              className="map-button-link"
              onClick={createLinkClick(Routes.Explore + navTab.tab)}
            >
              {navTab.display}
            </Button>
          ))}
        </Box>
      </div>
    </div>
  )
}

export default AreaStatsPopover
