import { FeatureCollection } from 'geojson'

import { RootState } from 'src/store/rootReducer'
import { Metric } from 'src/store/map/model'
import { NESWBounds } from 'google-map-react'

export const mapGeo = (state: RootState): FeatureCollection[] => state.map.geo
export const isMapLoading = (state: RootState): boolean => state.map.isLoading
export const getActiveMetric = (state: RootState): Metric =>
  state.map.activeMetric

export const getBoundsForCollection = (state: RootState): NESWBounds =>
  state.map.bounds[state.explore.activeArea?.collectionId ?? '']
