import { apiStateTemplate, generateApiReducers } from 'src/store/apiHelper'
import { createSlice } from '@reduxjs/toolkit'
import { InsightsState } from 'src/store/insights/model'
import { getInsights } from 'src/store/insights/actions'

const initialState = <InsightsState>{
  lotInsights: [],
  mortgageInsights: [],
  ...apiStateTemplate,
}

export const insightsSlice = createSlice({
  name: 'insights',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    const createApiReducer = generateApiReducers(builder)
    createApiReducer(getInsights, (state: InsightsState, action) => {
      state.lotInsights = action.payload.lotInsights
      state.mortgageInsights = action.payload.mortgageInsights
    })
  },
})
