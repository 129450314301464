import styled from 'styled-components'

import { TableCell, IconButton as MuiIconButton } from '@material-ui/core'

export const EndCell = styled(TableCell)`
  width: 160px;
`

export const IconButton = styled(MuiIconButton).attrs({
  role: 'link',
  color: 'primary',
})`
  background-color: ${({ theme }) => theme.palette.greys.pale};
`
