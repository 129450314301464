import { createAsyncThunk } from '@reduxjs/toolkit'
import { endpointUrl } from 'src/store/apiHelper'
import authenticatedFetch from 'src/utils/authenticatedFetch'

export const getPostcode = createAsyncThunk(
  'geographicalAreas/getPostcode',
  async (query: { collectionId: string; postcodeId: string }) => {
    const { collectionId, postcodeId } = query
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${collectionId}/postcode/${postcodeId}`
    )
    const data = await response.json()
    return { data, ids: { collectionId } }
  }
)

export const getAllPostcodes = createAsyncThunk(
  'geographicalAreas/getAllPostcodes',
  async (query: { collectionId: string }) => {
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${query.collectionId}/postcode`
    )
    const data = await response.json()
    return { data, ids: query }
  }
)

export const getSuburb = createAsyncThunk(
  'geographicalAreas/getSuburb',
  async (query: {
    collectionId: string
    postcodeId: string
    suburbId: string
  }) => {
    const { collectionId, postcodeId, suburbId } = query
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${collectionId}/postcode/${postcodeId}/suburb/${suburbId}`
    )
    const data = await response.json()
    return { data, ids: { collectionId, postcodeId } }
  }
)

export const getAllSuburbs = createAsyncThunk(
  'geographicalAreas/getAllSuburbs',
  async (query: { collectionId: string; postcodeId: string }) => {
    const response = await authenticatedFetch(
      `${endpointUrl}/collection/${query.collectionId}/postcode/${query.postcodeId}/suburb`
    )
    const data = await response.json()
    return { data, ids: query }
  }
)
