import { createAsyncThunk } from '@reduxjs/toolkit'
import authenticatedFetch from 'src/utils/authenticatedFetch'
import { endpointUrl } from 'src/store/apiHelper'
import { mapKindToInsight } from 'src/store/insights/utils'
import { hierarchySwitch } from 'src/store/explore/utils'
import { RootState } from 'src/store/rootReducer'
import { activeAreaSelector } from 'src/store/explore/selectors'

export const getInsights = createAsyncThunk<
  any,
  void,
  {
    state: RootState
  }
>('insights/getInsights', async (_, { getState }) => {
  let url = endpointUrl

  const area = activeAreaSelector(getState())

  if (area == null) return {}

  hierarchySwitch(
    getState(),
    areaStat => {
      const { collectionId } = areaStat.parents
      if (collectionId) url += `/collection/${collectionId}/sa2/${areaStat.id}`
    },
    geoStat => {
      const { collectionId, postcodeId } = geoStat.parents
      if (collectionId && postcodeId)
        url += `/collection/${collectionId}/postcode/${postcodeId}/suburb/${geoStat.id}`
      else if (collectionId)
        url += `/collection/${collectionId}/postcode/${geoStat.id}`
    }
  )

  if (url == null || url === endpointUrl) return {}

  url += '/insights'

  const response = await authenticatedFetch(url)
  const json = await response.json()

  return {
    lotInsights: json.data.lotInsights.map(mapKindToInsight),
    mortgageInsights: json.data.mortgageInsights.map(mapKindToInsight),
  }
})
