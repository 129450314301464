import { RootState } from 'src/store/rootReducer'
import { Insight } from 'src/store/insights/model'

export const isInsightsLoading = (state: RootState): boolean =>
  state.insights.isLoading

export const mortgageInsightsSelector = (state: RootState): Insight[] =>
  state.insights.mortgageInsights

export const lotInsightsSelector = (state: RootState): Insight[] =>
  state.insights.lotInsights
