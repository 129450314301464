import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { MouseEvent, useEffect, useState } from 'react'

import {
  lotInsightsSelector,
  mortgageInsightsSelector,
} from 'src/store/insights/selectors'
import { Metric } from 'src/store/map/model'
import { setActiveMetric } from 'src/store/map/actions'
import { ExploreTabs, Routes } from 'src/models/routes'
import { getActiveMetric } from 'src/store/map/selectors'
import { Insight, InsightType } from 'src/store/insights/model'
import {
  activeAreaSelector,
  insightsActiveSelector,
} from 'src/store/explore/selectors'
import TopStats from 'src/components/Views/Explore/Insight/topstats'
import InsightBox from 'src/components/Views/Explore/Insight/insight'
import LSBarChart from 'src/components/Views/Explore/Insight/barChart'
import LSLineChart from 'src/components/Views/Explore/Insight/lineChart'
import CardsInsight from 'src/components/Views/Explore/Insight/areaPerformance'
import { getInsights } from 'src/store/insights/actions'
import styled, { css } from 'styled-components'

interface InsightViewProps {
  visible: boolean
}

const InsightContainer = styled.div`
  margin-top: 40px;
`

const InsightTag = styled.span(
  ({ theme }) => css`
    margin-bottom: 40px;
    color: ${theme.palette.greys.medium};
    display: inline-block;
    padding: 0 3rem 0 2rem;
    height: 2.8rem;
    line-height: 2.8rem;
    font-size: 0.75rem;
    font-weight: 600;
    background: white;
    border-radius: 0 1.4rem 1.4rem 0;
  `
)

const InsightView: React.FC<InsightViewProps> = ({ visible }) => {
  const history = useHistory()

  const dispatch = useDispatch()

  const activeArea = useSelector(activeAreaSelector)
  const activeMetric = useSelector(getActiveMetric)
  const insightsActive = useSelector(insightsActiveSelector)

  useEffect(() => {
    if (!insightsActive) {
      history.push(Routes.Explore + ExploreTabs.Map)
    }
  }, [insightsActive])

  useEffect(() => {
    if (!insightsActive) return

    dispatch(getInsights())
  }, [insightsActive, activeArea])

  const [insightTab, setInsightTab] = useState<Metric>(activeMetric)

  let insights: Insight[] = []

  const lotInsights = useSelector(lotInsightsSelector)
  const mortgageInsights = useSelector(mortgageInsightsSelector)

  switch (insightTab) {
    case Metric.LotShare:
      insights = lotInsights
      break
    case Metric.MortgageShare:
      insights = mortgageInsights
      break
  }

  const handleTabClick = (event: MouseEvent<HTMLButtonElement>) => {
    const metric = event.currentTarget.textContent as Metric

    setInsightTab(metric)
    dispatch(setActiveMetric(metric))
  }

  return (
    <div className={`insight-view ${visible ? 'showme' : 'hideme'}`}>
      <div className="insigt-block">
        <div className="insight-block-header">
          <div className="tabs">
            <button
              onClick={handleTabClick}
              className={`tab${
                insightTab === Metric.LotShare ? ' selected' : ''
              }`}
            >
              {Metric.LotShare}
            </button>
            <button
              onClick={handleTabClick}
              className={`tab${
                insightTab === Metric.MortgageShare ? ' selected' : ''
              }`}
            >
              {Metric.MortgageShare}
            </button>
          </div>
        </div>
        {insights.map((insight: Insight, i) => {
          switch (insight.kind) {
            case InsightType.Overview:
              return <TopStats key={i} statData={insight} />
            case InsightType.Block:
              return (
                <div className="insight-block">
                  <InsightBox
                    key={i}
                    heading={insight.type}
                    type={insight.type}
                    insightText={[insight.title, insight.body]}
                  />
                </div>
              )
            case InsightType.ColumnChart:
              return (
                <LSBarChart
                  key={i}
                  data={insight.data}
                  format={insight.dataFormat}
                />
              )
            case InsightType.AreaPerformance:
            case InsightType.AreaGrowth:
              return (
                <InsightContainer>
                  <InsightTag>INSIGHT</InsightTag>
                  <CardsInsight insight={insight} />
                </InsightContainer>
              )
            case InsightType.LineChart:
              return (
                <LSLineChart
                  key={i}
                  title={insight.title}
                  lines={insight.lines}
                  labels={insight.labels}
                  format={insight.dataFormat}
                />
              )
          }
        })}
      </div>
    </div>
  )
}

export default InsightView
