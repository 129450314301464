import MetricSelector from 'src/components/Views/Explore/LSMap/MetricSelector'
// TODO real data?
export const mock_opts = [
  'Mortgage Share (Volume)',
  'Mortgage Share (Value)',
  'Lot Share',
  'Threshold',
  'Risk / Potential Trend',
  'Runoff / Growth Trend',
]

export interface LegendProps {
  options?: string[]
}

export const Legend: React.FC<LegendProps> = ({ options = mock_opts }) => {
  return (
    <div className="map-legend">
      <MetricSelector />

      <div className="gradient">
        <div className="grad-text">
          <span>High</span>
          <span>Low</span>
        </div>
        <div className="grad-bar">
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  )
}

export default Legend
