import { createAsyncThunk } from '@reduxjs/toolkit'
import authenticatedFetch from 'src/utils/authenticatedFetch'
import { endpointUrl } from 'src/store/apiHelper'

export const getAllMortgagees = createAsyncThunk(
  'mortgagees/getAllMortgagees',
  async () => {
    const response = await authenticatedFetch(`${endpointUrl}/mortgagee`)
    return await response.json()
  }
)
