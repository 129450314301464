import { ApiState } from 'src/store/apiHelper'
import {
  GeographicalGeoParents,
  GeographicalParents,
} from 'src/store/geographicalAreas/model'
import {
  StatisticalGeoParents,
  StatisticalParents,
} from 'src/store/statisticalAreas/model'
import { AreaStats, Stats, Totals } from 'src/store/sharedModels'
import { Metric, ServerMetric } from 'src/store/map/model'

export interface InsightsState extends ApiState {
  lotInsights: Insight[]
  mortgageInsights: Insight[]
}

export enum InsightType {
  Overview = 'overview',
  Block = 'block',
  ColumnChart = 'columnChart',
  LineChart = 'lineChart',
  AreaPerformance = 'performance',
  AreaGrowth = 'growth',
}

export enum InterestType {
  Performance = 'P',
  Growth = 'G',
}

export interface OverviewInsight {
  kind: InsightType.Overview

  count: number
  share: number
}

export interface BlockInsight {
  kind: InsightType.Block

  type: string
  title: string
  body: string
}

export type ValueFormat = 'numeric' | 'percent' | 'currency'

export interface BarData {
  label: string
  value: number
}

export interface ColumnChartInsight {
  kind: InsightType.ColumnChart
  dataFormat: string
  data: BarData[]
  legend: {
    id: string
    label: string
  }[]
}

export interface LineData {
  uuid: string
  data: number[]
}

export interface LineChartInsight {
  kind: InsightType.LineChart
  title: string
  dataFormat: string
  lines: LineData[]
  labels: string[]
  legend: string[]
}

export interface AreaPerformanceInsight {
  kind: InsightType.AreaPerformance
  cards: InterestCard[]
}

export interface AreaGrowthInsight {
  kind: InsightType.AreaGrowth
  cards: InterestCard[]
}

export type Insight =
  | OverviewInsight
  | BlockInsight
  | ColumnChartInsight
  | LineChartInsight
  | AreaPerformanceInsight
  | AreaGrowthInsight

export interface InterestCard {
  id: string
  name: string
  parents: StatisticalGeoParents | GeographicalGeoParents
  total: Totals
  stats: AreaStats
  metric: ServerMetric
}
