import { combineReducers } from '@reduxjs/toolkit'
import { collectionSlice } from 'src/store/collection'
import { exploreSlice } from 'src/store/explore'
import { mapSlice } from 'src/store/map'
import { insightsSlice } from 'src/store/insights'
import { mortgageesSlice } from 'src/store/mortgagees'
import { geographicalAreasSlice } from 'src/store/geographicalAreas'
import { statisticalAreasSlice } from 'src/store/statisticalAreas'

const rootReducer = combineReducers({
  collections: collectionSlice.reducer,
  geographicalAreas: geographicalAreasSlice.reducer,
  statisticalAreas: statisticalAreasSlice.reducer,
  explore: exploreSlice.reducer,
  map: mapSlice.reducer,
  insights: insightsSlice.reducer,
  mortgagees: mortgageesSlice.reducer,
})
export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
